import InventoryTable from "../../../components/table/withdata/Inventory";
import Card from "../../../components/Card";
import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";

function Dashboard(props) {
  const { outlet } = useContext(AppContext);

  return (
    <div>
      <div className="cards d-flex">
        <Card title={0} subTitle="Available Stock" className="success-light" />
        <Card title={0} subTitle="Low in Stock" className="warn-light" />
        <Card title={0} subTitle="Out of Stock" className="danger-light" />
        <Card title={0} subTitle="Requests Sent" />
        <Card title={0} subTitle="Received" />
      </div>
      <br />
      <InventoryTable outlet={outlet} />
    </div>
  );
}

export default Dashboard;
