function Cash({ color = "#F7941D" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1154_44935)">
        <path
          d="M15.833 11.6668V5.00016C15.833 4.0835 15.083 3.3335 14.1663 3.3335H2.49967C1.58301 3.3335 0.833008 4.0835 0.833008 5.00016V11.6668C0.833008 12.5835 1.58301 13.3335 2.49967 13.3335H14.1663C15.083 13.3335 15.833 12.5835 15.833 11.6668ZM14.1663 11.6668H2.49967V5.00016H14.1663V11.6668ZM8.33301 5.8335C6.94967 5.8335 5.83301 6.95016 5.83301 8.3335C5.83301 9.71683 6.94967 10.8335 8.33301 10.8335C9.71634 10.8335 10.833 9.71683 10.833 8.3335C10.833 6.95016 9.71634 5.8335 8.33301 5.8335ZM19.1663 5.8335V15.0002C19.1663 15.9168 18.4163 16.6668 17.4997 16.6668H3.33301C3.33301 15.8335 3.33301 15.9168 3.33301 15.0002H17.4997V5.8335C18.4163 5.8335 18.333 5.8335 19.1663 5.8335Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1154_44935">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Cash;
