const CheckIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5844 0.584351L14 1.99998L5 11L0 5.99998L1.41562 4.58435L5 8.17185L12.5844 0.584351Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
