import { useFormikContext } from "formik";
import { useEffect } from "react";
import Input from "../Input";

export default function FormInput({
  name,
  Wrapper = Input,
  value,
  listener,
  ...others
}) {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext();

  useEffect(() => {
    if (value) setFieldValue(name, value);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (typeof listener === "function") listener(values[name], setFieldValue);
    // eslint-disable-next-line
  }, [values[name]]);

  return (
    <Wrapper
      {...others}
      errorMessage={errors[name] && touched[name] ? errors[name] : null}
      onBlur={() => setFieldTouched(name)}
      onChange={(value) => setFieldValue(name, value)}
      value={values[name]}
    />
  );
}
