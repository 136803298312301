export const formatNumber = (n, pre = "₦") =>
  (pre || "") +
  (n < 1
    ? n?.toString()
    : n
        // ?.toFixed(2)
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ","));

export const formatIntCurrency = (amount, countryCode, currencyCode) => {
  if (!countryCode || !currencyCode) return formatNumber(amount, "");
  try {
    return new Intl.NumberFormat(countryCode, {
      style: "currency",
      currency: currencyCode,
    }).format(amount);
  } catch (error) {
    return formatNumber(amount, "");
  }
};
