const BookIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_1247)">
        <path
          d="M17.5002 4.16667C16.5752 3.875 15.5585 3.75 14.5835 3.75C12.9585 3.75 11.2085 4.08333 10.0002 5C8.79183 4.08333 7.04183 3.75 5.41683 3.75C3.79183 3.75 2.04183 4.08333 0.833496 5V17.2083C0.833496 17.4167 1.04183 17.625 1.25016 17.625C1.3335 17.625 1.37516 17.5833 1.4585 17.5833C2.5835 17.0417 4.2085 16.6667 5.41683 16.6667C7.04183 16.6667 8.79183 17 10.0002 17.9167C11.1252 17.2083 13.1668 16.6667 14.5835 16.6667C15.9585 16.6667 17.3752 16.9167 18.5418 17.5417C18.6252 17.5833 18.6668 17.5833 18.7502 17.5833C18.9585 17.5833 19.1668 17.375 19.1668 17.1667V5C18.6668 4.625 18.1252 4.375 17.5002 4.16667ZM17.5002 15.4167C16.5835 15.125 15.5835 15 14.5835 15C13.1668 15 11.1252 15.5417 10.0002 16.25V6.66667C11.1252 5.95833 13.1668 5.41667 14.5835 5.41667C15.5835 5.41667 16.5835 5.54167 17.5002 5.83333V15.4167Z"
          fill={color}
        />
        <path
          d="M14.5835 8.75C15.3168 8.75 16.0252 8.825 16.6668 8.96667V7.7C16.0085 7.575 15.3002 7.5 14.5835 7.5C13.1668 7.5 11.8835 7.74167 10.8335 8.19167V9.575C11.7752 9.04167 13.0835 8.75 14.5835 8.75Z"
          fill={color}
        />
        <path
          d="M10.8335 10.4083V11.7917C11.7752 11.2583 13.0835 10.9667 14.5835 10.9667C15.3168 10.9667 16.0252 11.0417 16.6668 11.1833V9.91667C16.0085 9.79167 15.3002 9.71667 14.5835 9.71667C13.1668 9.71667 11.8835 9.96667 10.8335 10.4083Z"
          fill={color}
        />
        <path
          d="M14.5835 11.9417C13.1668 11.9417 11.8835 12.1833 10.8335 12.6333V14.0167C11.7752 13.4833 13.0835 13.1917 14.5835 13.1917C15.3168 13.1917 16.0252 13.2667 16.6668 13.4083V12.1417C16.0085 12.0083 15.3002 11.9417 14.5835 11.9417Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_1247">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BookIcon;
