function Footer(props) {
  return (
    <footer className="raleway d-flex justify-center align-center">
      <span className="fs-14 text-center f-400">
        Copyright © {new Date().getFullYear()}{" "}
        <span className="f-700 text-brown">iCart Africa</span>. All rights
        reserved
      </span>
    </footer>
  );
}

export default Footer;
