import Card from "../../../components/Card";
import Revenue from "../../../components/charts/Revenue";
import RegisterStore from "../../../components/RegisterStore";
import SupplyRequests from "../../../components/table/withdata/SupplyRequests";

function Dashboard(props) {
  return (
    <div>
      <RegisterStore />
      <div className="d-flex tab-column flex-wrap gap-10">
        <div className="full-flex">
          <div className="cards d-flex">
            <Card subTitle="New Requests" />
            <Card subTitle="Delivered Supplies" />
          </div>
          <br />
          <Revenue random={false} title="Statistics" />
        </div>
        <div style={{ minWidth: "30%" }}>
          <div className="card activities">
            <h2 className="fs-20 text-primary f-600">Todo</h2>
            <br />
            <span className="fs-14">You have no todo!</span>
          </div>
          <br />
          <div className="card activities">
            <h2 className="fs-20 text-primary f-600">Activities</h2>
          </div>
        </div>
      </div>
      <br />
      <SupplyRequests title="Supply Requests" />
    </div>
  );
}

export default Dashboard;
