export const COLORS = Object.freeze({
  MAIN: {
    // BLUE: "#F7941D",
    // GRAY_BLACK: "#1A1918",
    // BLUE: "#0E79B7",
    BLUE: "#F7941D",
    DARK: "#614f41",
    GRAY_BLACK: "#3A3A3C",
    LIGHT: "#FFF9F5",
    YELLOW: "#dc8628",
    LIGHT02: "#614f4133",
    LIGHT03: "#614f41",
  },
  NEUTRALS: {
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    // TEXT_WHITE: "#FDEAD2",
    // BACKGROUND_WHITE: "#FFF9F2",
    TEXT_WHITE: "#D9DCDD",
    BACKGROUND_WHITE: "#EDEDED",
    TRANSPARENT: "transparent",
  },
  BLUE_TITNTS_SHADES: {
    // B_01: "#FCDBB4",
    // B_02: "#FAB868",
    // B_03: "#F8A643",
    // B_04: "#CE7B18",
    // B_05: "#7B4A0E",
    B_01: "#CFE4F1",
    B_02: "#86BCDB",
    B_03: "#368FC3",
    B_04: "#0C6598",
    B_05: "#073C5B",
  },
  CHARCOAL_TITNTS_SHADES: {
    C_01: "#D8D8D8",
    C_02: "#9C9C9D",
    C_03: "#5B5B5C",
    C_04: "#303032",
    C_05: "#1D1D1E",
  },
  WHITE_TINTS_SHADES: {
    W_01: "#E1E1E1",
    W_02: "#DBDBDB",
    W_03: "#C9C9C9",
    W_04: "#BCBCBC",
    W_05: "#A3A3A3",
  },
  ALERTS: {
    WARN: "#FF9800",
    INFO: "#2196F3",
    SUCCESS: "#4CAF50",
    DANGER: "#F44336",
  },
  BORDER: "#E6E7E9",
  TAB: "#edd5c2",
  // TAB: "#EDF6FC",
  BARS: {
    B_01: "#7ADE7E",
  },
});
