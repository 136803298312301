import { useEffect, useState } from "react";
import "../css/components/tab.css";
import { useNavigate } from "react-router-dom";

function Tab({ tabs = [], onSwitch, autoSelect, canNavigate, className = "" }) {
  const [active, setActive] = useState(null);
  const navigate = useNavigate();

  const handleSelect = (idx, tab) => {
    if (typeof onSwitch === "function") onSwitch(tab);
    if (canNavigate) navigate(tab.to);
    setActive(idx);
  };

  useEffect(() => {
    if (autoSelect) handleSelect(0, tabs[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`tabs d-flex flex-wrap br-t-10 b-10 ${className}`}>
      {tabs.map((t, idx) => (
        <button
          key={idx}
          onClick={() => {
            handleSelect(idx, t);
          }}
          className={`${active === idx ? "active-tab" : ""}`}
        >
          {t.badge && (
            <span className="badge fs-9 d-flex justify-center align-center">
              {t.badge}
            </span>
          )}
          <span className="fs-14 f-400">{t.title}</span>
        </button>
      ))}
    </div>
  );
}

export default Tab;
