const LocationIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_1222)">
        <path
          d="M9.99984 1.66666C6.77484 1.66666 4.1665 4.27499 4.1665 7.49999C4.1665 11.875 9.99984 18.3333 9.99984 18.3333C9.99984 18.3333 15.8332 11.875 15.8332 7.49999C15.8332 4.27499 13.2248 1.66666 9.99984 1.66666ZM5.83317 7.49999C5.83317 5.19999 7.69984 3.33332 9.99984 3.33332C12.2998 3.33332 14.1665 5.19999 14.1665 7.49999C14.1665 9.89999 11.7665 13.4917 9.99984 15.7333C8.2665 13.5083 5.83317 9.87499 5.83317 7.49999Z"
          fill={color}
        />
        <path
          d="M9.99984 9.58332C11.1504 9.58332 12.0832 8.65058 12.0832 7.49999C12.0832 6.3494 11.1504 5.41666 9.99984 5.41666C8.84924 5.41666 7.9165 6.3494 7.9165 7.49999C7.9165 8.65058 8.84924 9.58332 9.99984 9.58332Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_1222">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIcon;
