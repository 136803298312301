import { useNavigate } from "react-router-dom";
import ViewIcon from "../../assets/icons/ViewIcon";

function View({ link, state, title = "View Item", onClick }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (typeof onClick === "function") return onClick();
    else navigate(link, { state });
  };
  return (
    <button
      onClick={handleClick}
      className="d-flex justify-center align-center view-action"
    >
      <ViewIcon color="var(--main-yellow)" />
      <span className="d-block fs-11 f-700 text-primary">{title}</span>
    </button>
  );
}

export default View;
