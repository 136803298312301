import CollapsableHead from "./CollapsableHead";
import { useState } from "react";

function Collapsable({
  title,
  className,
  color,
  children,
  defaultOpened = false,
  showInfo,
}) {
  const [isOpened, setIsOpened] = useState(defaultOpened);
  const toggleIsOpened = () => setIsOpened(!isOpened);
  return (
    <>
      <div className="submit-menu-card">
        <CollapsableHead
          color={color}
          showInfo={showInfo}
          title={title}
          isOpened={isOpened}
          toggleOpened={toggleIsOpened}
          className={className}
        />
        <br />
        {isOpened && children}
      </div>
      <br />
    </>
  );
}

export default Collapsable;
