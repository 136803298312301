import { Form, FormInput, Submit } from "../../components/form";
import { registerSchema } from "../../utils/validators/auth";
import logo from "../../assets/logo-black.png";
import useAuth from "../../hooks/api/useAuth";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { path } from "../../utils/path";
import "../../css/pages/auth.css";
import { useState } from "react";
import Input from "../../components/Input";

const roles = [
  {
    label: "Creator",
    value: path.creators.url,
  },
  {
    label: "Supplier",
    value: path.suppliers.url,
  },
  {
    label: "Operator",
    value: path.iCartOperating.url,
  },
];

function Register(props) {
  const [role, setRole] = useState(path.creators.url);
  const { register, loading } = useAuth();
  return (
    <div className="auth-page inter d-flex flex-column">
      <div className="form d-flex form-container flex justify-center align-center">
        <div className="form d-flex flex-column form-container flex justify-center align-center">
          <img src={logo} alt="iCart Africa" />
          <br />
          <br />
          <div className="form-card shadow">
            <h2 className="fs-24 f-700 text-center text-primary">Register</h2>
            <br />
            <br />
            <Form
              validationSchema={registerSchema}
              onSubmit={(v) =>
                register(
                  {
                    ...v,
                    role: roles
                      .find((_) => _.value === role)
                      .label.toLowerCase(),
                  },
                  role
                )
              }
              initialValues={{
                email: "",
                password: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
              }}
            >
              <div className="inputs">
                <FormInput name="firstName" placeholder="First Name" />
                <FormInput name="lastName" placeholder="Last Name" />
              </div>
              <br />
              <FormInput name="email" placeholder="Email" />
              <br />
              <FormInput name="phoneNumber" placeholder="Phone Number" />
              <br />
              <Input
                select
                placeholder="Register as"
                options={roles}
                onChange={setRole}
              />
              <br />
              <FormInput password name="password" placeholder="Password" />
              <br />
              <Submit loading={loading} title="Continue" />
              <br />
              <span className="fs-14 f-400 text-center d-block">
                Already have an account?{" "}
                <Link to={path.auth.login} className="f-700 text-primary">
                  Login Here
                </Link>
              </span>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
