import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import Dashboard from "../pages/app/supplier/Dashboard";
import ViewRequest from "../pages/app/supplier/ViewRequest";
import AllRequests from "../pages/app/supplier/AllRequests";

function Suppliers(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
      <Route path={path.suppliers.requests.root} element={<AllRequests />} />
      <Route
        path={path.suppliers.requests.view.root}
        element={<ViewRequest />}
      />
    </Routes>
  );
}

export default Suppliers;
