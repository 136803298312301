import { useState } from "react";
import Chv from "../assets/icons/Chv";
import Button from "../components/Button";
import Icon from "../components/Icon";
import useModal from "../hooks/useModal";
import Modal from "../components/Modal";
import Input from "../components/Input";
import { SendIconTransformed } from "../assets/icons/SendIcon";
import FilePicker from "../components/FilePicker";

const FAQ = () => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleIsOpened = () => setIsOpened(!isOpened);
  return (
    <div className="faq">
      <button
        onClick={toggleIsOpened}
        className="faq-head d-flex justify-between align-center"
      >
        <h2 className="fs-14 f-400 text-brown">Lorem ipsum dolor sit?</h2>
        <Icon style={{ transform: `rotate(${isOpened ? 180 : 0}deg)` }}>
          <Chv color="#000" />
        </Icon>
      </button>
      {isOpened && (
        <div>
          <p className="fs-14 f-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
            accusantium id modi minus quam voluptate necessitatibus! Nesciunt
            sit a recusandae. Earum eveniet, maxime doloribus reprehenderit
            nulla odit delectus exercitationem aliquid.
          </p>
          <p className="fs-14 f-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
            accusantium id modi minus quam voluptate necessitatibus! Nesciunt
            sit a recusandae. Earum eveniet, maxime doloribus reprehenderit
            nulla odit delectus exercitationem aliquid.
          </p>
          <p className="fs-14 f-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
            accusantium id modi minus quam voluptate necessitatibus! Nesciunt
            sit a recusandae. Earum eveniet, maxime doloribus reprehenderit
            nulla odit delectus exercitationem aliquid.
          </p>
        </div>
      )}
    </div>
  );
};

function Complaints(props) {
  const { isOpened, openModal, closeModal } = useModal();
  return (
    <div>
      <Modal closeModal={closeModal} isVisible={isOpened}>
        <h2 className="fs-24 raleway f-700 text-primary">
          Create New Complaints
        </h2>
        <br />
        <Input placeholder="Subject" fontSize="fs-10" />
        <br />
        <Input textarea placeholder="What is your complain?" fontSize="fs-10" />
        <br />
        <FilePicker height={200} />
        <br />
        <div className="d-flex justify-end">
          <Button
            style={{ marginRight: 15 }}
            outline
            width={100}
            title="Close"
            onClick={closeModal}
          />
          <Button
            width={160}
            title="Send Message"
            lSvg={<SendIconTransformed />}
            onClick={closeModal}
          />
        </div>
      </Modal>
      <div className="notes">
        <h2 className="fs-24 raleway f-700 text-primary">
          Make New Complaints
        </h2>
        <br />
        <p className="fs-14 text-blue f-400">
          See our frequently asked questions. If you don’t see your complain
          among them, then proceed to create a new report. When new complains
          are created, it gets added to the FAQ list.
        </p>
        <br />
        <Button onClick={openModal} width={220} title="Submit New Complain" />
        <br />
      </div>
      <div className="faqs">
        <FAQ />
        <FAQ />
        <FAQ />
        <FAQ />
        <FAQ />
        <FAQ />
      </div>
    </div>
  );
}

export default Complaints;
