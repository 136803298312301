import { Route, Routes } from "react-router-dom";
import Inventory from "./Inventory";
import { path } from "../utils/path";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import AppContext from "../contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import Support from "./Support";
import Wallet from "./Wallet";
import Logout from "../pages/auth/Logout";
import NotFound from "../components/NotFound";
import "../css/pages/index.css";
import Suppliers from "./Suppliers";
import Creators from "./Creators";
import Operators from "./Operators";
import AuthContext from "../contexts/AuthContext";
import BaseHome from "../pages/app/Home";
import useConfig from "../hooks/api/useConfig";
import Loader from "../components/Loader";
import { formatIntCurrency } from "../utils/numbers";
import useQuery from "../hooks/useQuery";
import store from "../utils/store";
import { roles } from "../utils/vars";
import AudioPlayer from "../components/AudioPlayer";
import useNetworkStatus from "../hooks/useNetworkStatus";
import axiosClient from "../api";
import { io } from "socket.io-client";
import orderAlarm from "../assets/audio/alarm.mp3";
import Profile from "../pages/app/accounts/Profile";

function Base(props) {
  // const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 760);
  const [region, setRegion] = useState({ symbol: "₦" });
  const [currentCity, setCurrentCity] = useState(null);
  const { user, settings } = useContext(AuthContext);
  const storedOutlet = store.getItem("outlet");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const query = useQuery();
  const [playOrderAlarm, setPlayOrderAlarm] = useState(false);
  const { isOnline } = useNetworkStatus();
  const {
    addCountry,
    addCity,
    cities,
    countries,
    loading,
    getCities,
    updateCity,
    getCountries,
    updateCountry,
  } = useConfig();

  useEffect(() => {
    getCountries();
    getCities((data) => {
      if (data.length) {
        if (storedOutlet)
          setCurrentCity(
            data.find((_) => _._id === storedOutlet.location.city)
          );
        else setCurrentCity(data[data.length - 1]);
      }
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store.getItem("settings")?.isOperatingDevice) {
      const socket = io(axiosClient.getUri().replace("/api", ""), {
        jsonp: false,
      });
      socket.on("new-order", (data) => {
        setShouldRefresh(true);
        setPlayOrderAlarm(true);
      });
      socket.on("new-payment", (data) => {
        setShouldRefresh(true);
        setPlayOrderAlarm(true);
      });
    }
  }, [isOnline]);

  if (loading)
    return (
      <div className="page d-flex align-center justify-center">
        <Loader loading />
      </div>
    );

  return (
    <AppContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
        region,
        setRegion,
        addCountry,
        addCity,
        cities,
        countries,
        loading,
        updateCity,
        currentCity,
        updateCountry,
        setCurrentCity,
        shouldRefresh,
        setShouldRefresh,
        outlet:
          query.get("outlet") || storedOutlet?._id || user?.staff?.outlet?._id,
        formatCurrency: (amount, currency) => {
          let city = currentCity;
          if (currency) {
            city = cities.find((c) => c.country.currency === currency);
          }
          return formatIntCurrency(
            amount || 0,
            `en-${city?.country?.code}`,
            city?.country?.currency
          );
        },
      }}
    >
      <div className="page inter d-flex main-layout">
        <NavBar />
        <div className="layout d-flex flex-column">
          <Header />
          <div className="layout-content">
            <Routes>
              <Route
                path={path.root}
                element={
                  user.role === "operator" ? (
                    <Operators />
                  ) : user.role === roles.creator ? (
                    <Creators />
                  ) : user.role === roles.supplier ? (
                    <Suppliers />
                  ) : (
                    <BaseHome />
                  )
                }
              />
              <Route path={path.iCartOperating.root} element={<Operators />} />
              <Route path={path.creators.root} element={<Creators />} />
              <Route path={path.profile} element={<Profile />} />
              <Route path={path.suppliers.root} element={<Suppliers />} />
              <Route path={path.inventory.root} element={<Inventory />} />
              <Route path={path.support.root} element={<Support />} />
              <Route path={path.wallet.root} element={<Wallet />} />
              <Route path={path.auth.logout} element={<Logout />} />
              <Route path={path.all} element={<NotFound />} />
            </Routes>
          </div>
        </div>
        <AudioPlayer
          setIsPlaying={setPlayOrderAlarm}
          isPlaying={playOrderAlarm}
          audioSrc={orderAlarm}
        />
      </div>
    </AppContext.Provider>
  );
}

export default Base;
