import { createConceptBasic } from "../../utils/validators/creators";
import { Form, FormInput, Submit } from "../form";
import useConcepts from "../../hooks/api/useConcepts";
import { objectToFormData, selectFromObject } from "../../utils/array";
import urls from "../../api/urls";
import { useState } from "react";
import FilePicker from "../FilePicker";

/*  */

function BasicInfo({
  id,
  setSearchParams,
  onFinished,
  loading: loading2,
  concept,
  addDataToConcept,
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { loading, submitConcept } = useConcepts();

  const saveConcept = async (data) => {
    let concepData;
    if (selectedImage) {
      data = objectToFormData(data);
      data.append("logo", selectedImage);
    }
    if (id) {
      concepData = await addDataToConcept(
        id,
        data,
        urls.business.concepts.details
      );
    } else {
      concepData = await submitConcept(data);
      setSearchParams({ id: concepData.concept._id });
    }
    if (concepData) onFinished(concepData);
  };
  return (
    <div>
      <Form
        onSubmit={saveConcept}
        validationSchema={createConceptBasic}
        initialValues={
          concept
            ? selectFromObject(
                ["name", "description", "origin", "bestServeTo"],
                concept.concept
              )
            : {
                name: "",
                description: "",
                origin: "",
                bestServeTo: "",
              }
        }
      >
        <FormInput name="name" placeholder="Concept Name" />
        <br />
        <FormInput name="origin" placeholder="Concept Origin" />
        <br />
        <FormInput
          name="description"
          placeholder="Concept Description"
          textarea
        />
        <br />
        <FormInput
          name="bestServeTo"
          select
          options={[
            {
              label: "Adult",
              value: "adult",
            },
            {
              label: "Child",
              value: "child",
            },
            {
              label: "Teen",
              value: "teen",
            },
            {
              label: "Adult & Child",
              value: "adult-child",
            },
            {
              label: "Adult & Teen",
              value: "adult-teen",
            },
            {
              label: "Child & Teen",
              value: "child-teen",
            },
            {
              label: "All ages",
              value: "all-ages",
            },
          ]}
          placeholder="Best Served To"
        />
        <br />
        <FilePicker
          initial={concept?.concept?.logo}
          onSelect={setSelectedImage}
          placeholder="Image"
        />
        <br />
        <Submit loading={loading || loading2} title="Save & Continue" />
      </Form>
    </div>
  );
}

export default BasicInfo;
