const ArrowUpIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.914442 0.91435L0.914442 2.00201L5.22265 2.00587L0.528748 6.69977L1.30014 7.47116L5.99404 2.77725L5.99018 7.08546L7.08556 7.08546L7.08556 0.91435L0.914442 0.91435Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowUpIcon;
