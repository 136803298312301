import SupplyRequests from "../../../components/table/withdata/SupplyRequests";

function AllRequests(props) {
  return (
    <div>
      <SupplyRequests showBtn={false} title="Supply Requests" />
    </div>
  );
}

export default AllRequests;
