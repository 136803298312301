import { Route, Routes } from "react-router-dom";
import Redirect from "../components/Redirect";
import Login from "../pages/auth/Login";
import { path } from "../utils/path";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Settings from "../components/Settings";

function Auth({ user }) {
  return (
    <Routes>
      <Route path={path.auth.login} element={<Login />} />
      <Route path={path.auth.signUp} element={<Register />} />
      <Route path={path.auth.forgotPassword} element={<ForgotPassword />} />
      <Route path={path.all} element={<Redirect url={path.auth.login} />} />
      <Route path={"/settings"} element={<Settings />} />
    </Routes>
  );
}

export default Auth;
