import { Link } from "react-router-dom";
import "../css/components/card.css";
import { formatNumber } from "../utils/numbers";
import Icon from "./Icon";

export function CardWithIcon({
  icon,
  title = 0,
  children,
  symbol = "",
  hover = true,
  className = "",
  formatTitle = true,
  subTitle = "Something",
  ...props
}) {
  return (
    <Link
      {...props}
      className={`card d-flex flex-column ${className} ${hover ? "hover" : ""}`}
    >
      <Icon>{icon}</Icon>
      <br />
      <span
        style={{ textAlign: "left" }}
        className="sp text-brown fs-28 f-600 d-block"
      >
        {formatTitle ? formatNumber(title, symbol) : title}
      </span>
      <span
        style={{ textAlign: "left" }}
        className="sp fs-14 f-400 raleway d-block"
      >
        {subTitle}
      </span>
      {children}
    </Link>
  );
}

function Card({
  subTitle = "Something",
  title = 0,
  hover = true,
  className = "justify-center align-center",
  children,
  symbol = "",
  formatTitle = true,
  ...props
}) {
  return (
    <Link
      {...props}
      className={`card d-flex flex-column ${className} ${hover ? "hover" : ""}`}
    >
      <span className="sp fs-14 f-400 raleway">{subTitle}</span>
      <br />
      <span className="sp fs-28 f-600">
        {formatTitle ? formatNumber(title, symbol) : title}
      </span>
      {children}
    </Link>
  );
}

export default Card;
