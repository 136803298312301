import { useState } from "react";

const useModal = (openedDefault = false) => {
  const [isOpened, setIsOpened] = useState(openedDefault);
  const toggleIsOpened = () => setIsOpened(!isOpened);
  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return { isOpened, toggleIsOpened, closeModal, openModal };
};
export default useModal;
