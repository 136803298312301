const SuppliersIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_320)">
        <path
          d="M14.1663 4.16666H2.49967C1.58301 4.16666 0.833008 4.90832 0.833008 5.83332V13.3333H2.49967C2.49967 14.7167 3.61634 15.8333 4.99967 15.8333C6.38301 15.8333 7.49967 14.7167 7.49967 13.3333H12.4997C12.4997 14.7167 13.6163 15.8333 14.9997 15.8333C16.383 15.8333 17.4997 14.7167 17.4997 13.3333H19.1663V9.16666L14.1663 4.16666ZM12.4997 5.83332H13.333L15.833 8.33332H12.4997V5.83332ZM7.49967 5.83332H10.833V8.33332H7.49967V5.83332ZM2.49967 5.83332H5.83301V8.33332H2.49967V5.83332ZM4.99967 14.375C4.42467 14.375 3.95801 13.9083 3.95801 13.3333C3.95801 12.7583 4.42467 12.2917 4.99967 12.2917C5.57467 12.2917 6.04134 12.7583 6.04134 13.3333C6.04134 13.9083 5.57467 14.375 4.99967 14.375ZM14.9997 14.375C14.4247 14.375 13.958 13.9083 13.958 13.3333C13.958 12.7583 14.4247 12.2917 14.9997 12.2917C15.5747 12.2917 16.0413 12.7583 16.0413 13.3333C16.0413 13.9083 15.5747 14.375 14.9997 14.375ZM17.4997 11.6667H16.8497C16.3913 11.1583 15.733 10.8333 14.9997 10.8333C14.2663 10.8333 13.608 11.1583 13.1497 11.6667H6.84967C6.39134 11.1583 5.74134 10.8333 4.99967 10.8333C4.25801 10.8333 3.60801 11.1583 3.14967 11.6667H2.49967V9.99999H17.4997V11.6667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_320">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuppliersIcon;
