function Hammer(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_52_8024)">
        <mask
          id="mask0_52_8024"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <rect width="40" height="40" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_52_8024)">
          <path
            d="M8.33366 31.6671H25.0003C25.4725 31.6671 25.8684 31.8268 26.1878 32.1463C26.5073 32.4657 26.667 32.8616 26.667 33.3338C26.667 33.806 26.5073 34.2018 26.1878 34.5213C25.8684 34.8407 25.4725 35.0004 25.0003 35.0004H8.33366C7.86144 35.0004 7.4656 34.8407 7.14616 34.5213C6.82671 34.2018 6.66699 33.806 6.66699 33.3338C6.66699 32.8616 6.82671 32.4657 7.14616 32.1463C7.4656 31.8268 7.86144 31.6671 8.33366 31.6671ZM13.7087 24.5421L9.00033 19.8338C8.36144 19.1949 8.03505 18.4102 8.02116 17.4796C8.00727 16.5491 8.31977 15.7643 8.95866 15.1254L10.167 13.9171L19.667 23.3338L18.4587 24.5421C17.8198 25.181 17.0281 25.5004 16.0837 25.5004C15.1392 25.5004 14.3475 25.181 13.7087 24.5421ZM26.667 16.3338L17.2503 6.83378L18.4587 5.62544C19.0975 4.98655 19.8823 4.67405 20.8128 4.68794C21.7434 4.70183 22.5281 5.02822 23.167 5.66711L27.8753 10.3754C28.5142 11.0143 28.8337 11.806 28.8337 12.7504C28.8337 13.6949 28.5142 14.4866 27.8753 15.1254L26.667 16.3338ZM33.167 32.1671L12.5837 11.5838L14.917 9.25044L35.5003 29.8338C35.8059 30.1393 35.9587 30.5282 35.9587 31.0004C35.9587 31.4727 35.8059 31.8616 35.5003 32.1671C35.1948 32.4727 34.8059 32.6254 34.3337 32.6254C33.8614 32.6254 33.4725 32.4727 33.167 32.1671Z"
            fill="#F7941D"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_52_8024">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Hammer;
