import { useNavigate } from "react-router-dom";
import "../css/components/btn.css";
import Loader from "./Loader";

function Button({
  width = "100%",
  height = 48,
  title,
  bg = "bg-primary",
  className = "",
  textClassName = "",
  fontSize = "fs-14",
  outline,
  lSvg,
  rSvg,
  style = {},
  color = "text-white",
  loading,
  disabled,
  borderColor,
  link,
  onClick,
  borderRadius = 8,
  state,
  ...props
}) {
  const navigate = useNavigate();
  const handleNavigate = () => navigate(link, { state });
  return (
    <button
      {...props}
      onClick={link ? handleNavigate : onClick}
      disabled={loading || disabled}
      style={{ ...style, width, height, borderColor, borderRadius }}
      className={`btn d-flex justify-center align-center ${bg} ${className} ${
        outline ? "btn-outline" : "btn-normal"
      }`}
    >
      {lSvg}
      {loading ? (
        <Loader className="white" loading />
      ) : (
        title && (
          <span
            className={`f-700 d-block ${fontSize} ${color} ${textClassName}`}
          >
            {title}
          </span>
        )
      )}
      {rSvg}
    </button>
  );
}

export default Button;
