const SupportIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_1357)">
        <path
          d="M17.4998 10.1833C17.4998 5.60833 13.9498 2.5 9.99984 2.5C6.0915 2.5 2.49984 5.54167 2.49984 10.2333C1.99984 10.5167 1.6665 11.05 1.6665 11.6667V13.3333C1.6665 14.25 2.4165 15 3.33317 15H4.1665V9.91667C4.1665 6.69167 6.77484 4.08333 9.99984 4.08333C13.2248 4.08333 15.8332 6.69167 15.8332 9.91667V15.8333H9.1665V17.5H15.8332C16.7498 17.5 17.4998 16.75 17.4998 15.8333V14.8167C17.9915 14.5583 18.3332 14.05 18.3332 13.45V11.5333C18.3332 10.95 17.9915 10.4417 17.4998 10.1833Z"
          fill={color}
        />
        <path
          d="M7.49984 11.6667C7.96007 11.6667 8.33317 11.2936 8.33317 10.8333C8.33317 10.3731 7.96007 10 7.49984 10C7.0396 10 6.6665 10.3731 6.6665 10.8333C6.6665 11.2936 7.0396 11.6667 7.49984 11.6667Z"
          fill={color}
        />
        <path
          d="M12.4998 11.6667C12.9601 11.6667 13.3332 11.2936 13.3332 10.8333C13.3332 10.3731 12.9601 10 12.4998 10C12.0396 10 11.6665 10.3731 11.6665 10.8333C11.6665 11.2936 12.0396 11.6667 12.4998 11.6667Z"
          fill={color}
        />
        <path
          d="M14.9999 9.19167C14.5999 6.81667 12.5332 5 10.0416 5C7.51656 5 4.79989 7.09167 5.01656 10.375C7.07489 9.53333 8.62489 7.7 9.06656 5.46667C10.1582 7.65833 12.3999 9.16667 14.9999 9.19167Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_1357">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SupportIcon;
