function NotFound(props) {
  return (
    <div
      style={{ height: "100%" }}
      className="notfound full-flex d-flex justify-center align-center"
    >
      <h2>Not Found!</h2>
    </div>
  );
}

export default NotFound;
