function Chv({ color = "#182430", ...props }) {
  return (
    <svg
      {...props}
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 8.3335L0 0.333496H16L8 8.3335Z" fill={color} />
    </svg>
  );
}

export default Chv;
