const AccountIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_1263)">
        <path
          d="M9.99984 1.66666C5.39984 1.66666 1.6665 5.39999 1.6665 9.99999C1.6665 14.6 5.39984 18.3333 9.99984 18.3333C14.5998 18.3333 18.3332 14.6 18.3332 9.99999C18.3332 5.39999 14.5998 1.66666 9.99984 1.66666ZM5.8915 15.2333C6.24984 14.4833 8.43317 13.75 9.99984 13.75C11.5665 13.75 13.7582 14.4833 14.1082 15.2333C12.9748 16.1333 11.5498 16.6667 9.99984 16.6667C8.44984 16.6667 7.02484 16.1333 5.8915 15.2333ZM15.2998 14.025C14.1082 12.575 11.2165 12.0833 9.99984 12.0833C8.78317 12.0833 5.8915 12.575 4.69984 14.025C3.84984 12.9083 3.33317 11.5167 3.33317 9.99999C3.33317 6.32499 6.32484 3.33332 9.99984 3.33332C13.6748 3.33332 16.6665 6.32499 16.6665 9.99999C16.6665 11.5167 16.1498 12.9083 15.2998 14.025ZM9.99984 4.99999C8.38317 4.99999 7.08317 6.29999 7.08317 7.91666C7.08317 9.53332 8.38317 10.8333 9.99984 10.8333C11.6165 10.8333 12.9165 9.53332 12.9165 7.91666C12.9165 6.29999 11.6165 4.99999 9.99984 4.99999ZM9.99984 9.16666C9.30817 9.16666 8.74984 8.60832 8.74984 7.91666C8.74984 7.22499 9.30817 6.66666 9.99984 6.66666C10.6915 6.66666 11.2498 7.22499 11.2498 7.91666C11.2498 8.60832 10.6915 9.16666 9.99984 9.16666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_1263">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountIcon;
