import Dashboard from "../pages/app/wallet/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import "../css/pages/wallet.css";
import Withdraw from "../pages/app/wallet/Withdraw";
import ChangePin from "../pages/app/wallet/ChangePin";

function Wallet(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
      <Route path={path.wallet.pin.path} element={<ChangePin />} />
      <Route path={path.wallet.withdraw.path} element={<Withdraw />} />
    </Routes>
  );
}

export default Wallet;
