import useProfile from "../../hooks/api/useProfile";
import Loader from "../../components/Loader";
import { useEffect } from "react";

function Splash({ setIsReady }) {
  const { restoreUser } = useProfile();

  useEffect(() => {
    (async () => {
      await restoreUser();
      setIsReady(true);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="page d-flex align-center justify-center">
      <Loader loading />
    </div>
  );
}

export default Splash;
