import { useNavigate } from "react-router-dom";

function ID({ id, label = "View Item", className = "", link, onClick, state }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (typeof onClick === "function") return onClick();
    else navigate(link, { state });
  };
  return (
    <button onClick={handleClick} className="item-id btn-plain">
      <span className={`d-block text-left fs-14 f-400 text-blue ${className}`}>
        {id}
      </span>
      <span className="d-block text-left fs-10 f-500 text-brown">{label}</span>
    </button>
  );
}

export default ID;
