import { toast } from "react-toastify";
import urls from "../../api/urls";
import useAPI from "./useAPI";
import { objectToFormData } from "../../utils/array";
import useProfile from "./useProfile";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";

function useBrand(props) {
  const [allBrands, setAllBrands] = useState([]);
  const { loading, post, put, get, refreshToken } = useAPI();
  const { restoreUser } = useProfile();

  const getAllBrands = async (pageNumber = 1, limit = 10, approved = true) => {
    const { data, error } = await get(
      addParamsToUrl(urls.brands.baseUrl, { pageNumber, limit, approved })
    );
    if (error) return;
    setAllBrands(data.brands);
  };

  const updateBrandStatus = async (id, status, cb) => {
    const { error, message } = await put(
      urls.brands.updateStatus.replace(":id", id),
      { status }
    );
    if (error) return;
    toast.success(message);
    if (typeof cb === "function") cb();
  };

  const approveBrand = async (id, cb) => {
    const { error, message } = await put(
      urls.brands.approve.replace(":id", id)
    );
    if (error) return;
    toast.success(message);
    if (typeof cb === "function") cb();
  };

  const registerBrand = async (fdata, img, cb) => {
    fdata = objectToFormData(fdata);
    fdata.append("logo", img);
    fdata.append("banner", img);
    const { data, error, message } = await post(urls.brands.baseUrl, fdata);
    if (error || !data) return;
    toast.info(message);
    await refreshToken();
    await restoreUser();
    if (typeof cb === "function") cb();
  };

  return {
    updateBrandStatus,
    registerBrand,
    approveBrand,
    getAllBrands,
    allBrands,
    loading,
  };
}
export default useBrand;
