const AnalyticsIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_1232)">
        <path
          d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333Z"
          fill={color}
        />
        <path d="M7.50016 10H5.8335V14.1667H7.50016V10Z" fill={color} />
        <path d="M14.1667 5.83334H12.5V14.1667H14.1667V5.83334Z" fill={color} />
        <path
          d="M10.8332 11.6667H9.1665V14.1667H10.8332V11.6667Z"
          fill={color}
        />
        <path d="M10.8332 8.33334H9.1665V10H10.8332V8.33334Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_532_1232">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnalyticsIcon;
