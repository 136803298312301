import { useEffect, useState } from "react";
import CheckBox from "./CheckBox";
import Button from "./Button";
import Plus from "../assets/icons/Plus";
import Minus from "../assets/icons/Minus";
import WithImage from "./table/WithImage";

const ItemCounter = ({
  item,
  input,
  updateItems,
  showUnit,
  addOption = true,
  units,
}) => {
  const [priority, setPriority] = useState(null);
  const [supplyUnit, setSupplyUnit] = useState(null);
  const [count, setCount] = useState(item?.quantity);
  const increment = () => setCount(count + 1);
  const decrement = () => {
    if (count <= 0) return;
    setCount(count - 1);
  };
  const handleCheckChange = (e) => {
    if (e & !count) setCount(1);
    else setCount(0);
  };

  useEffect(() => {
    if (count)
      updateItems((items) =>
        items.map((_) => {
          if (_._id === item._id) _.quantity = count;
          return _;
        })
      );
    else if (count <= 0)
      updateItems((items) => items.filter((_) => _._id !== item._id));

    // eslint-disable-next-line
  }, [count]);

  useEffect(() => {
    if (priority)
      updateItems((items) =>
        items.map((_) => {
          if (_._id === item._id) _.priority = priority;
          return _;
        })
      );

    // eslint-disable-next-line
  }, [priority]);

  useEffect(() => {
    if (supplyUnit)
      updateItems((items) =>
        items.map((_) => {
          if (_._id === item._id) _.supplyUnit = supplyUnit;

          return _;
        })
      );

    // eslint-disable-next-line
  }, [supplyUnit]);

  return (
    <tr className="fs-14 d-flex f-400 text-blue menu-row">
      {addOption ? (
        <td className="f-700 d-flex align-center t-check">
          <CheckBox onChange={handleCheckChange} value={!!count} />
        </td>
      ) : null}
      <td className="full-flex d-flex align-center">
        <WithImage text={item?.name} imageUrl={item.imageUrl} />
      </td>
      <td className="counter d-flex align-center">
        <Button
          onClick={decrement}
          disabled={count <= 0}
          width={32}
          height={32}
          rSvg={<Minus />}
        />
        {input ? (
          <input
            className="raleway"
            type="number"
            value={count}
            onChange={(e) => setCount(parseInt(e.target.value))}
          />
        ) : (
          <span className="fs-14 f-400 d-block">{count}</span>
        )}
        <Button onClick={increment} width={32} height={32} rSvg={<Plus />} />
      </td>
      {showUnit ? (
        <>
          <td
            // style={{   width: 60 }}
            className="d-flex justify-center align-center"
          >
            <select onChange={(e) => setSupplyUnit(e.target.value)}>
              <option disabled>Measurement</option>
              {units
                ?.find((_) => _.value === item.unitOfMeasure)
                ?.conversions?.map((_, idx) => (
                  <option value={_.value} key={_.value}>
                    {_.name}
                  </option>
                ))}
            </select>
            {/* <span style={{ padding: 5 }}>{item.unitOfMeasure}</span> */}
          </td>
          <td
            style={{ width: 60, marginLeft: 10 }}
            className="d-flex justify-center align-center"
          >
            <select onChange={(e) => setPriority(e.target.value)}>
              <option disabled>Priority</option>
              <option value="moderate">Moderate</option>
              <option value="high">High</option>
            </select>
          </td>
        </>
      ) : null}
    </tr>
  );
};

export default ItemCounter;
