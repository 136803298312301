function Link({ color = "#F7941D" }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1094_16237)">
        <path
          d="M12.6667 13.1667H3.33333V3.83333H8V2.5H3.33333C2.59333 2.5 2 3.1 2 3.83333V13.1667C2 13.9 2.59333 14.5 3.33333 14.5H12.6667C13.4 14.5 14 13.9 14 13.1667V8.5H12.6667V13.1667ZM9.33333 2.5V3.83333H11.7267L5.17333 10.3867L6.11333 11.3267L12.6667 4.77333V7.16667H14V2.5H9.33333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1094_16237">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Link;
