import { useEffect, useState } from "react";
import Input from "./Input";
import Modal from "./Modal";
import ItemCounter from "./ItemCounter";
import useInventory from "../hooks/api/useInventory";
import Loader from "./Loader";
import Image from "./Image";
import Button from "./Button";
import useConfig from "../hooks/api/useConfig";
import Group from "./FlexGroup";
import Collapsable from "./Collapsable";
import FormattedInput from "./FormattedInput";
import useBounceQuery from "../hooks/useBounceQuery";

const OrderItems = ({ name, id, handleChange, unitOfMeasure, units }) => (
  <Collapsable title={name}>
    <div className="d-flex gap-10">
      <div className="inputs full-flex ">
        <FormattedInput
          onChange={(v) => handleChange(id, "quantity", v)}
          placeholder="Quantity"
        />
        <Input
          select
          placeholder="Select Unit"
          onChange={(v) => handleChange(id, "supplyUnit", v)}
          options={[
            { label: unitOfMeasure, value: unitOfMeasure },
            ...(units
              ?.find((_) => _.value === unitOfMeasure)
              ?.conversions?.map((_) => ({ label: _.name, value: _.value })) ||
              []),
          ]}
        />
      </div>
      <div style={{ maxWidth: 200 }}>
        <Input
          placeholder="Priority"
          select
          onChange={(v) => handleChange(id, "priority", v)}
          options={[
            { label: "High", value: "high" },
            { label: "Moderate", value: "moderate" },
          ]}
        />
      </div>
    </div>
  </Collapsable>
);

function RequestMaterials({ isOpened, outlet, type = "", closeModal }) {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState([]);
  const [note, setNote] = useState("");
  const {
    searchMaterial,
    setMaterials,
    sendMaterialsRequest,
    materials,
    loading,
  } = useInventory();
  const { getUnits, units, loading: loading2 } = useConfig();

  const { setQuery, query } = useBounceQuery({
    setItems: setMaterials,
    search: searchMaterial,
  });

  const saveRequest = () =>
    sendMaterialsRequest(
      {
        items: items
          .filter((_) => _.quantity)
          .map((_) => ({
            material: _._id,
            supplyUnit: _.supplyUnit,
            requestedQuantity: _.quantity,
            priority: _.priority || "moderate",
          })),
        note,
      },
      outlet,
      () => {
        setItems([]);
        // closeModal();
      }
    );

  // useEffect(() => {
  //   searchMaterial(search);
  //   // eslint-disable-next-line
  // }, [search]);

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h2 className="fs-24 raleway f-700 text-primary">Request {type}</h2>
      <br />
      <div className="d-flex gap-10">
        <div style={{ width: "30%" }}>
          <Input placeholder="Search item" value={query} onChange={setQuery} />
          <br />
          {loading || loading2 ? (
            <div
              style={{
                width: "100%",
                marginBottom: 10,
                marginTop: 10,
              }}
              className="d-flex justify-center align-center"
            >
              <Loader loading />
            </div>
          ) : null}
          <div
            style={{ maxHeight: "calc(100vh - 200px)" }}
            className="search-rusults"
          >
            {materials
              // eslint-disable-next-line
              .filter((_) => !items.find((xx) => xx._id == _._id))
              .map((_, idx) => (
                <div
                  className="material-search-item d-flex align-center"
                  key={idx}
                >
                  <Image src={_.imageUrl} />
                  <div>
                    <span className="d-block fs-16 f-600 text-brown">
                      {_.name}
                    </span>
                    <span className="d-block fs-10">{_.tags.join(", ")}</span>
                  </div>
                  <Button
                    onClick={() => {
                      setItems((items) => [{ ..._, quantity: 1 }, ...items]);
                      setQuery("");
                    }}
                    title="Add"
                    width={70}
                    height={25}
                    fontSize="fs-10"
                    className="bg-brown"
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="full-flex">
          <Group
            units={units}
            items={items}
            setItems={setItems}
            Element={OrderItems}
            AddElement={() => <></>}
          />
          <br />
          <Input value={note} onChange={setNote} placeholder="Note" textarea />
          <br />
          <div className="d-flex gap-10">
            {/* <Button onClick={closeModal} title="Cancel" outline width={110} /> */}
            <Button
              width={200}
              disabled={!items.length}
              loading={loading}
              onClick={saveRequest}
              title="Send Request"
            />
          </div>
        </div>
      </div>

      {/* {items.map((_, idx) => (
        <ItemCounter
          units={units}
          showUnit
          input
          item={_}
          updateItems={setItems}
          addOption={false}
          key={idx}
        />
      ))} */}
      {/* {items.map((_, idx) => (
        <ItemCounter
          units={units}
          showUnit
          input
          item={_}
          updateItems={setItems}
          addOption={false}
          key={idx}
        />
      ))} */}
    </>
  );
}

export default RequestMaterials;
