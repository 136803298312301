const ArrowEndRightIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8L4 4V12L10 8ZM10 8V12H11V4H10V8Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowEndRightIcon;
