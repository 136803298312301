import { useContext, useRef } from "react";
import Button from "./Button";
import { useReactToPrint } from "react-to-print";
import AuthContext from "../contexts/AuthContext";
import store from "../utils/store";
import AppContext from "../contexts/AppContext";
import { formatDateTime } from "../utils/date";

function Reciept({ order, handleClose }) {
  const { formatCurrency } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const ref = useRef(null);

  const handlePrint = useReactToPrint({
    contentRef: ref,
  });

  return (
    <div className="reciept-cnt">
      <Button
        style={{ marginLeft: "auto" }}
        className="btn-submit"
        onClick={handlePrint}
        title="Print"
      />
      <br />
      <div ref={ref} class="reciept-body">
        <h2 class="center">iCart Africa</h2>
        <p class="center">Plot 11 Inna Plaza</p>
        <p class="center">Gaduwa Abuja</p>
        <p class="center">Tel: +234 903 792 0018</p>
        <br />
        <hr />
        <div class="r-info">
          <p>
            <strong>Date:</strong> {formatDateTime(order.createdAt, false).date}
          </p>
          <p>
            <strong>Time:</strong> {formatDateTime(order.updatedAt, false).date}
          </p>
          <p>
            <strong>Operator:</strong> {user.firstName} {user.lastName}
          </p>
          <p>
            <strong>Order ID: {order.id}</strong>
          </p>
          <p>
            <strong>iCart ID:</strong> {store.getItem("outlet")?.name}
          </p>
        </div>
        <hr />
        <table>
          {order.items.map((i, idx) => (
            <tr class="sp-tr">
              <td>
                {i.quantity} {i.item.name}
              </td>
              <td>{formatCurrency((i.priceStamp * i.quantity).toFixed(2))}</td>
            </tr>
          ))}
        </table>
        <hr />
        <hr />
        <table>
          <tr>
            <td>VAT: 0% VAT</td>
          </tr>
          <tr>
            <td>
              VAT Amount: <strong>{formatCurrency((0.0).toFixed(2))}</strong>
            </td>
          </tr>
          <tr>
            <td>
              Delivery Fee:{" "}
              <strong>{formatCurrency(order.totalDeliveryFee)}</strong>
            </td>
          </tr>
        </table>
        <br />
        <hr />
        <table>
          <tr class="sp-tr">
            <td>VAT</td>
            <td>{formatCurrency((0.0).toFixed(2))}</td>
          </tr>
          <tr class="sp-tr">
            <td>Total</td>
            <td>{formatCurrency(order.totalPrice + order.totalDeliveryFee)}</td>
          </tr>
        </table>
        <hr />
        <hr />
        <br />
        <h3 class="center">
          THANK YOU ({order.customerInformation.phoneNumber})
        </h3>
        <span class="center"> DELIVER TO </span>
        <p class="center">
          <strong>{order.deliveryTo.address}</strong>
        </p>
      </div>
      <br />
      <Button
        style={{ marginLeft: "auto" }}
        className="btn-submit"
        onClick={handleClose}
        title="Close"
      />
    </div>
  );
}

export default Reciept;
