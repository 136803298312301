const SubmissionIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_2187)">
        <path
          d="M15 10.8333H14.4333L12.7667 12.5H14.3583L15.8333 14.1667H4.16667L5.65 12.5H7.35833L5.69167 10.8333H5L2.5 13.3333V16.6667C2.5 17.5833 3.24167 18.3333 4.15833 18.3333H15.8333C16.75 18.3333 17.5 17.5917 17.5 16.6667V13.3333L15 10.8333ZM15.8333 16.6667H4.16667V15.8333H15.8333V16.6667ZM9.45 12.5167C9.775 12.8417 10.3 12.8417 10.625 12.5167L15.925 7.21667C16.25 6.89167 16.25 6.36667 15.925 6.04167L11.8 1.91667C11.4833 1.58334 10.9583 1.58334 10.6333 1.90834L5.325 7.21667C5 7.54167 5 8.06667 5.325 8.39167L9.45 12.5167ZM11.2167 3.675L14.1667 6.625L10.0417 10.75L7.09167 7.8L11.2167 3.675Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_2187">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SubmissionIcon;
