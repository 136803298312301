export const formFrom = (start, end) => {
  const output = [];
  for (let i = start; i <= end; i++) {
    output.push(i);
  }
  return output;
};

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (char) {
      const random = (Math.random() * 16) | 0; // Random number between 0 and 15
      const value = char === "x" ? random : (random & 0x3) | 0x8; // Ensure 'y' bits match 8, 9, A, or B
      return value.toString(16); // Convert to hexadecimal
    }
  );
}

export const getNestedValueFromObject = (obj, target) => {
  if (!target || !target.split) return null;
  target = target.split(".");
  let data = obj[target[0]];
  for (let i = 1; i < target.length; i++) {
    try {
      data = data[target[i]];
    } catch (error) {
      return null;
    }
  }
  return data;
};

export const selectFromObject = (fields = [], obj) => {
  const output = {};
  fields.forEach((f) => {
    output[f] = obj[f];
  });
  return output;
};

export const excludeFromObject = (fields = [], obj) => {
  const output = {};
  Object.keys(obj).forEach((f) => {
    if (!fields.includes(f)) {
      output[f] = obj[f];
    }
  });
  return output;
};

export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = null
) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        objectToFormData(value, formData, fullKey);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          objectToFormData(item, formData, `${fullKey}[${index}]`);
        });
      } else {
        formData.append(fullKey, value);
      }
    }
  }

  return formData;
}
