// Duplication

import { useNavigate } from "react-router-dom";
import PlusIcon from "../../../assets/icons/PlusIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import statuses from "../../../utils/statuses";
import Status from "../../Status";
import ID from "../ID";
import Table from "../Table";
import View from "../View";
import WithImage from "../WithImage";
import { path } from "../../../utils/path";
import useConcepts from "../../../hooks/api/useConcepts";
import usePagination from "../../../hooks/usePagination";
import { useContext, useEffect } from "react";
import AuthContext from "../../../contexts/AuthContext";

export const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Code",
    target: "_id",
    render: (id) => (
      <ID
        link={path.creators.edit.url + id}
        id={id.split("").reverse().join("").slice(0, 6).toUpperCase()}
        label="Edit Concept"
      />
    ),
  },
  {
    title: "Name",
    target: ["logo", "name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Description",
    target: "description",
  },
  {
    title: "Origin",
    target: "origin",
  },
  // {
  //   title: "Menu Count",
  //   target: "menu",
  //   render: (v) => (
  //     <span className="d-flex justify-center align-center">{v}</span>
  //   ),
  // },
  {
    title: "Status",
    target: "status",
    render: (v) => (
      <Status
        light
        className={
          v === "rejected"
            ? statuses.danger
            : v === "pending"
            ? statuses.warn
            : v === "active"
            ? statuses.active
            : statuses.info
        }
        text={v}
      />
    ),
  },
  {
    title: "Active",
    target: "active",
    render: (v) => (
      <Status
        light
        className={!v ? statuses.danger : statuses.info}
        text={v ? "active" : "not active"}
      />
    ),
  },
  {
    title: "Actions",
    target: "id",
    render: (v) => <View />,
  },
];

function MyConcepts(props) {
  const { user } = useContext(AuthContext);
  const { setPageNumber, limit, pageNumber } = usePagination({
    initialPage: 1,
    initialLimit: 5,
  });
  const { getConcepts, loading, requests } = useConcepts();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.brand) getConcepts(pageNumber, limit);
    // eslint-disable-next-line
  }, [pageNumber, limit]);

  return (
    <div>
      <Table
        btn2={{
          title: "View all",
          icon: <ViewIcon color="var(--main-yellow)" />,
        }}
        btn1={{
          title: "Submit New Concept",
          icon: <PlusIcon />,
          onClick: () => navigate(path.creators.new.url),
        }}
        loading={loading}
        limit={limit}
        onPaginate={setPageNumber}
        totalCount={40}
        head={tableHead}
        data={requests}
        title="My Concepts"
      />
    </div>
  );
}

export default MyConcepts;

/* [
            // {
            //   menu: 2,
            //   code: "DNDR",
            //   name: "Danderu",
            //   status: "rejected",
            // },
            // {
            //   menu: 3,
            //   code: "MASA",
            //   name: "Masa",
            //   status: "active",
            //   description: "Lorem ipsum dolor sit...",
            // },
            // {
            //   menu: 3,
            //   code: "PSOUP",
            //   name: "Cow tail & legs Pepper Soup",
            //   status: "pending",
            // },
          ] */
