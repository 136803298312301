import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import "../css/pages/support.css";
import Dashboard from "../pages/app/support/Dashboard";
import Videos from "../pages/app/support/Videos";
import Video from "../pages/app/support/Video";
import Compliance from "../pages/app/support/Compliance";
import Complaints from "./Complaints";

function Support(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
      <Route path={path.support.videos.path} element={<Videos />} />
      <Route
        path={path.support.videos.path + path.params}
        element={<Video />}
      />
      <Route path={path.support.compliance.path} element={<Compliance />} />
      <Route path={path.support.complaints.path} element={<Complaints />} />
    </Routes>
  );
}

export default Support;
