import { useNavigate } from "react-router-dom";
import VideoInfo from "../../../components/Video";
import img1 from "../../../assets/img1.png";
import img2 from "../../../assets/img2.png";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";

function Video(props) {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-wrap video-details">
      <Modal />
      <div className="video-text-content">
        <h2 className="fs-24 raleway f-700 text-primary">
          Crispy Fries Recipe & Preparation Tutorials
        </h2>
        <br />
        <p className="fs-14 text-blue f-400">
          Lorem ipsum dolor sit amet consectetur. Donec enim iaculis diam
          aliquet id purus suspendisse ullamcorper faucibus. Commodo accumsan
          rutrum volutpat arcu.
        </p>
        <br />
        <div className="d-flex ingredient-instructions">
          <div>
            <div className="c-img">
              <Image />
            </div>
            <br />
            <h2 className="fs-18 f-600 text-brown">Ingredients Required</h2>
            <br />
            <ul>
              <li className="fs-14 f-400">4 chilli peppers</li>
              <li className="fs-14 f-400">1 container of salt</li>
              <li className="fs-14 f-400">1 spoon of cinnamon powder</li>
              <li className="fs-14 f-400">Irish potatoes</li>
              <li className="fs-14 f-400">Seasoning</li>
              <li className="fs-14 f-400">Groundut oil</li>
            </ul>
            <br />
            <h2 className="fs-18 f-600 text-brown">Tools Required</h2>
            <br />
            <ul>
              <li className="fs-14 f-400">Frying Pan</li>
              <li className="fs-14 f-400">Gas Cylinder</li>
              <li className="fs-14 f-400">Burner gas cylinder</li>
              <li className="fs-14 f-400">Turning sticks</li>
              <li className="fs-14 f-400">Soup spoons</li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <div className="creator-info">
        <div className="personal-info">
          <div></div>
        </div>
        <div className="d-flex flex-wrap videos">
          <VideoInfo img={img1} navigate={navigate} />
          <VideoInfo img={img2} navigate={navigate} />
        </div>
      </div>
    </div>
  );
}

export default Video;
