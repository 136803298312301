import React, { useState } from "react";
import OTPInput from "../../../components/PIN";
import useWallet from "../../../hooks/api/useWallet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";

function ChangePin(props) {
  const [currentPin, setCurrentPin] = useState(null);
  const [newPin, setNewPin] = useState(null);
  const { changePin, loading } = useWallet();
  const navigate = useNavigate();

  return (
    <Modal isVisible>
      {newPin ? (
        <React.Fragment>
          <OTPInput
            loading={loading}
            onChange={(v) => {
              if (!v) setNewPin(null);
            }}
            onComplete={(pin) => {
              if (pin !== newPin) {
                toast.warn("Pin not matching!");
                return setNewPin(null);
              }
              changePin(
                {
                  oldPin: currentPin,
                  pin,
                },
                () => navigate(-1)
              );
            }}
            placeholder="Confirm new pin"
          />
        </React.Fragment>
      ) : currentPin ? (
        <div>
          <OTPInput
            onChange={(v) => {
              if (!v) setCurrentPin(null);
            }}
            placeholder="Enter new pin"
            onComplete={setNewPin}
          />
        </div>
      ) : (
        <OTPInput placeholder="Enter current pin" onComplete={setCurrentPin} />
      )}
    </Modal>
  );
}

export default ChangePin;
