import PlayIcon from "../assets/icons/PlayIcon";
import { path } from "../utils/path";
import Button from "./Button";
import Image from "./Image";

const VideoInfo = ({ img, navigate }) => {
  const navigateVideo = () => navigate(`${path.support.videos.url + 1234}`);
  return (
    <div className="video bordered">
      <button onClick={navigateVideo} className="thumbnail">
        <div className="overlay d-flex justify-center align-center">
          <PlayIcon />
        </div>
        <img src={img} />
      </button>
      <div className="video-info">
        <h2 className="text-left fs-14 f-600 text-brown raleway d-block">
          Video title
        </h2>
        <div className="d-flex desc-date">
          <span className="d-block fs-10 f-400">Creator Name</span>
          <span className="d-block fs-10 f-400">01/01/2024</span>
        </div>
        <div className="d-flex justify-between align-center">
          <span className="fs-10 f-400">
            Watch Time: <span className="fs-10 f-600 text-brown">05:26</span>
          </span>
          <Button
            fontSize="fs-10"
            title="Read Recipe"
            outline
            width={100}
            height={25}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoInfo;
