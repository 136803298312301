import DashboardIcon from "./DashboardIcon";
import BusinessIcon from "./BusinessIcon";
import LocationIcon from "./LocationIcon";
import AnalyticsIcon from "./AnalyticsIcon";
import BookIcon from "./BookIcon";
import AccountIcon from "./AccountIcon";
import MessagesIcon from "./MessagesIcon";
import OrdersIcon from "./OrdersIcon";
import OnlineOrdersIcon from "./OnlineOrdersIcon";
import PreOrdersIcon from "./PreOrdersIcon";
import InventoryIcon from "./InventoryIcon";
import SalesIcon from "./SalesIcon";
import WalletIcon from "./WalletIcon";
import SupportIcon from "./SupportIcon";
import SettingsIcon from "./SettingsIcon";
import LogoutIcon from "./LogoutIcon";
import KitchenIcon from "./KitchenIcon";
import StoreIcon from "./StoreIcon";
import SuppliersIcon from "./SuppliersIcon";
import SubmissionIcon from "./SubmissionIcon";
import SendIcon from "./SendIcon";
import TimeIcon from "./TimeIcon";
import PageRightIcon from "./PageRightIcon";
import PageBackIcon from "./PageBackIcon";
import NotificationIcon from "./NotificationIcon";
import OptionIcon from "./OptionIcon";
import ViewIcon from "./ViewIcon";
import DescIcon from "./DescIcon";
import CheckIcon from "./CheckIcon";
import CardIcon from "./CardIcon";
import PlayIcon from "./PlayIcon";
import CancelIcon from "./CancelIcon";
import BarsIcon from "./BarsIcon";
import BackArrowIcon from "./BackArrowIcon";
import ArrowUpIcon from "./ArrowUpIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import AngleLeftIcon from "./AngleLeftIcon";
import AngleRightIcon from "./AngleRightIcon";
import UserIcon from "./UserIcon";
import AlertIcon from "./AlertIcon";
import UserLoadingIcon from "./UserLoadingIcon";
import PlusIcon from "./PlusIcon";
import Inbox from "./Inbox";
import Video from "./Video";
import Hammer from "./Hammer";
import Delete from "./Delete";
import Minus from "./Minus";
import Plus from "./Plus";

export const icons = {
  DashboardIcon,
  BusinessIcon,
  LocationIcon,
  AnalyticsIcon,
  BookIcon,
  AccountIcon,
  MessagesIcon,
  OrdersIcon,
  OnlineOrdersIcon,
  PreOrdersIcon,
  InventoryIcon,
  SalesIcon,
  WalletIcon,
  SupportIcon,
  SettingsIcon,
  LogoutIcon,
  KitchenIcon,
  StoreIcon,
  SuppliersIcon,
  SubmissionIcon,
  SendIcon,
  TimeIcon,
  PageRightIcon,
  PageBackIcon,
  NotificationIcon,
  OptionIcon,
  ViewIcon,
  DescIcon,
  CheckIcon,
  CardIcon,
  CancelIcon,
  BarsIcon,
  BackArrowIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  AngleLeftIcon,
  AngleRightIcon,
  PlayIcon,
  UserIcon,
  AlertIcon,
  UserLoadingIcon,
  PlusIcon,
  Inbox,
  Video,
  Hammer,
  Delete,
  Minus,
  Plus,
};
