import Table from "../../../components/table/Table";
import useInventory from "../../../hooks/api/useInventory";
import { useContext, useEffect } from "react";
import usePagination from "../../../hooks/usePagination";
import ID from "../../../components/table/ID";
import { path } from "../../../utils/path";
import { formatDateTime } from "../../../utils/date";
import Status from "../../../components/Status";
import { baseStatuses } from "../../../utils/statuses";
import WithImage from "../../../components/table/WithImage";
import AppContext from "../../../contexts/AppContext";

const tableHead = [
  {
    title: "Sent By",
    target: "requestedBy",
    render: (v) => (
      <WithImage
        imageUrl={v.profileImage}
        text={`${v.firstName} ${v.lastName}`}
      />
    ),
  },
  {
    title: "Supply Status",
    target: "status",
    render: (v) => {
      return <Status text={v} light className={baseStatuses[v]} />;
    },
  },
  {
    title: "Time Sent",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
  {
    title: "Last updated",
    target: "updatedAt",
    render: (v) => formatDateTime(v).dateTime,
  },
];

function SentRequests(props) {
  const { getMaterialRequests, products, loading, pagination } = useInventory();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const { outlet } = useContext(AppContext);

  useEffect(() => {
    if (outlet) getMaterialRequests(outlet, pageNumber, limit, "");
    // eslint-disable-next-line
  }, [pageNumber, limit, outlet]);
  return (
    <div>
      <Table
        onPaginate={setPageNumber}
        limit={limit}
        totalCount={pagination.total}
        head={[
          {
            title: "#",
            target: "#",
          },
          {
            title: "Request ID",
            target: "*",
            render: (
              value,
              id = value._id
                .split("")
                .reverse()
                .join("")
                .slice(0, 6)
                .toUpperCase()
            ) => (
              <ID
                link={path.inventory.requests.view.url + `?id=${value._id}`}
                id={id}
                label="View Request"
                state={value}
              />
            ),
          },
          ...tableHead,
        ]}
        data={products}
        title="Sent Requests"
        loading={loading}
      />
    </div>
  );
}

export default SentRequests;
