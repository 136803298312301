// Get value from a nested
// const obj = { v1: { v2: { v3: 'Value' } } }
// getNestedValueFromObject(obj, 'v1.v2.v3') -> 'Value'

export const getNestedValueFromObject = (obj, target) => {
  if (!target || !target.split) return null;
  target = target.split(".");
  let data = obj[target[0]];
  for (let i = 1; i < target.length; i++) {
    try {
      data = data[target[i]];
    } catch (error) {
      return null;
    }
  }
  return data;
};

export function doesValueExists(value) {
  // Check if value is a string and not empty
  if (typeof value === "string" && value.trim() !== "") {
    return true;
  }

  // Check if value is a valid number (not NaN)
  if (typeof value === "number" && !isNaN(value)) {
    return true;
  }

  // Check if value is an array and contains at least one item
  if (Array.isArray(value) && value.length > 0) {
    return true;
  }

  // Check if value is an object and has at least one key
  if (
    value &&
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.keys(value).length > 0
  ) {
    return true;
  }

  // If none of the conditions are met, return false
  return false;
}
