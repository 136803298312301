import { useContext, useState } from "react";
import useWindow from "../hooks/useWindow";
import AppContext from "../contexts/AppContext";
import FilePicker from "./FilePicker";
import { Form, FormInput, Submit } from "./form";
import { createStoreSchema } from "../utils/validators/creators";
import AuthContext from "../contexts/AuthContext";
import useStore from "../hooks/api/useStore";

function RegisterStore() {
  const { isCollapsed, cities } = useContext(AppContext);
  const { registerStore, loading } = useStore();
  const { user } = useContext(AuthContext);
  const [logo, setLogo] = useState(null);
  const { width } = useWindow();

  if (user.supplier) return null;

  return (
    <div
      className="full-flex d-flex justify-center align-center"
      style={{
        width: width - (isCollapsed ? 80 : 230),
        height: "100%",
        right: 0,
        top: 0,
        backgroundColor: "#FFF9F2",
        position: "fixed",
        padding: 20,
        overflowY: "auto",
        zIndex: 10,
      }}
    >
      <br />
      <Form
        validationSchema={createStoreSchema}
        onSubmit={(v) => registerStore(v, logo)}
        initialValues={{ storeName: "", city: "" }}
      >
        <div style={{ maxWidth: 450, width: "100%" }}>
          <h2 className="raleway fs-24 f-700 text-primary">
            Register Your Store
          </h2>
          <br />
          <FormInput
            name="storeName"
            placeHolderBg="#FFF9F2"
            placeholder="Store Name"
          />
          <br />
          <FormInput
            name="city"
            placeHolderBg="#FFF9F2"
            placeholder="City"
            select
            options={cities.map((city) => {
              return { label: city.name, value: city._id };
            })}
          />
          <br />
          <FilePicker onSelect={setLogo} height={200} />
          <br />
          <Submit loading={loading} title="Save & Proceed" />
        </div>
      </Form>
    </div>
  );
}

export default RegisterStore;
