const PageBackIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12V4H5V8V12H4ZM5 8L11 4V12L5 8Z"
        fill={color}
      />
    </svg>
  );
};

export default PageBackIcon;
