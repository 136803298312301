function Todo(props) {
  return (
    <div className="card activities">
      <h2 className="fs-20 text-primary f-600">Todo</h2>
      <br />
      <span className="fs-14">You have no todo!</span>
    </div>
  );
}

export default Todo;
