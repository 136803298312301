const AngleRightIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 8L5 12V4L11 8Z" fill={color} />
    </svg>
  );
};

export default AngleRightIcon;
