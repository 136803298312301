function Minus({ color = "#fff" }) {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66732 1.66683H0.333984V0.333496H9.66732V1.66683Z"
        fill={color}
      />
    </svg>
  );
}

export default Minus;
