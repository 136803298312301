import { useContext } from "react";
import RequestMaterials from "../../../components/RequestMaterials";
import { requestTypes } from "../../../utils/vars";
import AppContext from "../../../contexts/AppContext";

function NewRequest(props) {
  const { outlet } = useContext(AppContext);
  return (
    <div>
      <RequestMaterials type={requestTypes.supply} outlet={outlet} />
    </div>
  );
}

export default NewRequest;
