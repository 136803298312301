const PreOrdersIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8.5H15.5V6.83333H0.5V8.5ZM0.5 11.8333H15.5V10.1667H0.5V11.8333ZM0.5 5.16666H15.5V3.5H0.5V5.16666ZM0.5 0.166664V1.83333H15.5V0.166664H0.5Z"
        fill={color}
      />
    </svg>
  );
};

export default PreOrdersIcon;
