function StepsIndicator({ index = 1, className = "", onClick, end, skiped }) {
  return (
    <button
      onClick={onClick}
      className={`btn-plain full-flex indicator d-flex align-center gap-10 ${
        skiped ? "skiped" : className
      }`}
    >
      <span className="d-flex justify-center align-center fs-16 f-500">
        {skiped ? (
          <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="var(--alerts-danger)"
              d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8.933-2.721-2.722c-.146-.146-.339-.219-.531-.219-.404 0-.75.324-.75.749 0 .193.073.384.219.531l2.722 2.722-2.728 2.728c-.147.147-.22.34-.22.531 0 .427.35.75.751.75.192 0 .384-.073.53-.219l2.728-2.728 2.729 2.728c.146.146.338.219.53.219.401 0 .75-.323.75-.75 0-.191-.073-.384-.22-.531l-2.727-2.728 2.717-2.717c.146-.147.219-.338.219-.531 0-.425-.346-.75-.75-.75-.192 0-.385.073-.531.22z"
              fillRule="nonzero"
            />
          </svg>
        ) : className === "completed" ? (
          <svg
            width="15"
            height="11"
            viewBox="0 0 15 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2523 0.583984L14.668 1.99961L5.66797 10.9996L0.667969 5.99961L2.08359 4.58398L5.66797 8.17148L13.2523 0.583984Z"
              fill="white"
            />
          </svg>
        ) : (
          index
        )}
      </span>
      <div />
      {end && (
        <span className="d-flex justify-center align-center fs-16 f-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--main-yellow)"
              d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
            />
          </svg>
        </span>
      )}
    </button>
  );
}

export default StepsIndicator;
