import { useState } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";

function useWallet(props) {
  const [wallet, setWallet] = useState({ balance: 0 });
  const [transactions, setTransactions] = useState([]);
  const { get, loading, patch, fdelete, post } = useAPI();

  const getMyWallet = async () => {
    const { data, error } = await get(urls.wallet.me);
    if (error) return;
    setWallet(data.wallet);
  };

  const initTopUp = async (amount, cb) => {
    const { error, data } = await post(urls.wallet.fund, { amount });
    if (error) return;
    if (typeof cb === "function") cb();
    window.open(data.checkoutUrl, "_blank");
  };

  const creditWallet = async (body, cb) => {
    const { error, data } = await post(urls.wallet.adminFund, body);
    if (error) return;
    if (typeof cb === "function") cb();
  };

  const debitWallet = async (body, cb) => {
    const { error, data } = await post(urls.wallet.adminDebit, body);
    if (error) return;
    if (typeof cb === "function") cb();
  };

  const getTransactions = async (pageNumber = 1, limit = 10, account) => {
    const { data, error } = await get(
      addParamsToUrl(urls.wallet.transactions, { pageNumber, limit, account })
    );
    if (error) return;
    setTransactions(data.transactions);
  };
  const getUserTransactions = async (pageNumber = 1, limit = 10, id) => {
    const { data, error } = await get(
      addParamsToUrl(urls.accounts.transactions.replace(":id", id), {
        pageNumber,
        limit,
      })
    );
    if (error) return;
    setTransactions(data.transactions);
  };

  const getUserWallet = async (id) => {
    const { data, error } = await get(urls.accounts.wallet.replace(":id", id));
    if (error) return;
    console.log(data.wallet);
    setWallet(data.wallet);
  };

  const blockWallet = async (id, cb) => {
    const { data, error } = await fdelete(
      urls.accounts.wallet.replace(":id", id)
    );
    if (error) return;
    toast.warn(data.message || "Wallet blocked!");
    await getUserWallet(id);
    if (typeof cb === "function") cb();
  };

  const unBlockWallet = async (id, cb) => {
    const { data, error } = await patch(
      urls.accounts.wallet.replace(":id", id)
    );
    if (error) return;
    toast.info(data.message || "Wallet ublocked");
    await getUserWallet(id);
    if (typeof cb === "function") cb();
  };

  const withdraw = async (fdata, cb) => {
    const { error, data } = await post(urls.wallet.withdraw, fdata);
    if (error || !data) return;
    if (typeof cb === "function") cb();
    toast.success(data.message);
  };

  const changePin = async (fdata, cb) => {
    const { error, data } = await patch(urls.wallet.changePin, fdata);
    if (error || !data) return;
    if (typeof cb === "function") cb();
    toast.success(data.message);
  };

  return {
    loading,
    wallet,
    withdraw,
    initTopUp,
    changePin,
    debitWallet,
    getMyWallet,
    blockWallet,
    creditWallet,
    transactions,
    getUserWallet,
    unBlockWallet,
    getTransactions,
    getUserTransactions,
  };
}

export default useWallet;
