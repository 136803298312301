const MessagesIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_1272)">
        <path
          d="M3.33317 3.33334H16.6665V13.3333H4.30817L3.33317 14.3083V3.33334ZM3.33317 1.66667C2.4165 1.66667 1.67484 2.41667 1.67484 3.33334L1.6665 18.3333L4.99984 15H16.6665C17.5832 15 18.3332 14.25 18.3332 13.3333V3.33334C18.3332 2.41667 17.5832 1.66667 16.6665 1.66667H3.33317ZM4.99984 10H14.9998V11.6667H4.99984V10ZM4.99984 7.50001H14.9998V9.16667H4.99984V7.50001ZM4.99984 5.00001H14.9998V6.66667H4.99984V5.00001Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_1272">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MessagesIcon;
