import useModal from "../hooks/useModal";
import { getImageUrl } from "../utils/helpers";
import Modal from "./Modal";

function Image({ src, alt, ...props }) {
  const { isOpened, openModal, closeModal } = useModal();

  const img = (
    <img
      alt={alt}
      onClick={openModal}
      {...props}
      {...getImageUrl(
        src ||
          "https://www.shutterstock.com/image-vector/image-icon-600nw-211642900.jpg"
      )}
    />
  );
  // eslint-disable-next-line
  return (
    <>
      <Modal isVisible={isOpened} closeModal={closeModal}>
        <div className="modal-img">{img}</div>
      </Modal>
      {img}
    </>
  );
}

export default Image;
