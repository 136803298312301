import Collapsable from "../Collapsable";
import InfoText from "../TextInfo";
import urls from "../../api/urls";
import Button from "../Button";
import Status from "../Status";
import Image from "../Image";

function Review({ onFinished, loading, concept, addDataToConcept, id }) {
  const submitForReview = async () => {
    const response = await addDataToConcept(
      id,
      {},
      urls.business.concepts.submit
    );
    if (response) onFinished(concept);
  };

  return (
    <div>
      <Collapsable
        defaultOpened
        color="var(--yellow-tints-5)"
        showInfo={false}
        className="fs-14 d-block text-brown f-700"
        title="Basic Information"
      >
        <div className="d-flex mobile-column gap-10">
          <InfoText
            className="full-flex"
            text={concept?.concept?.name}
            title="Name"
          />
          <InfoText
            className="full-flex"
            text={concept?.concept?.origin}
            title="Origin"
          />
        </div>
        <br />
        <InfoText title="Description" text={concept?.concept?.description} />
        <br />
        <div className="img-pain">
          <Image alt={concept?.concept?.name} src={concept?.concept?.logo} />
        </div>
      </Collapsable>
      <Collapsable
        color="var(--yellow-tints-5)"
        showInfo={false}
        className="fs-14 d-block text-brown f-700"
        title="Ingredients & Machineries"
      >
        <InfoText className="full-flex" title="Ingredients">
          <div className="d-flex flex-wrap gap-10 align-center">
            {concept?.ingredients?.map((_, idx) => (
              <Status
                style={{ marginTop: !idx ? 5 : 0 }}
                text={_.name}
                className="status-tint5"
                key={idx}
              />
            ))}
          </div>
        </InfoText>
        <br />
        <InfoText className="full-flex" title="Tools & Machineries">
          <div className="d-flex flex-wrap gap-10 align-center">
            {concept?.machineries?.map((_, idx) => (
              <Status
                style={{ marginTop: !idx ? 5 : 0 }}
                text={_.name}
                className="status-tint5"
                key={idx}
              />
            ))}
          </div>
        </InfoText>
      </Collapsable>
      <Collapsable
        color="var(--yellow-tints-5)"
        showInfo={false}
        className="fs-14 d-block text-brown f-700"
        title={`Menu (${concept?.menu?.length || 0})`}
      >
        {concept?.menu.map((_, idx) => (
          <>
            <InfoText key={idx} title="Name" text={_.name} />
            <br />
          </>
        ))}
      </Collapsable>
      <Collapsable
        color="var(--yellow-tints-5)"
        showInfo={false}
        className="fs-14 d-block text-brown f-700"
        title="Packaging"
      >
        <InfoText title="Method" text={concept?.concept?.packaging} />
        <br />
        <div className="gap-10 flex-wrap d-flex">
          {concept?.concept?.packageImages.map((img) => (
            <Image
              style={{
                width: 200,
                objectFit: "cover",
                height: 200,
                borderRadius: 4,
              }}
              key={img}
              src={img}
            />
          ))}
        </div>
      </Collapsable>
      <br />
      <Button
        loading={loading}
        onClick={submitForReview}
        title="Submit for Review"
      />
    </div>
  );
}

export default Review;
