import useAuth from "../../hooks/api/useAuth";
import { useEffect } from "react";

function Logout() {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  });

  return null;
}

export default Logout;
