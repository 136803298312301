function Inbox(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_48_8007)">
        <mask
          id="mask0_48_8007"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <rect width="40" height="40" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_48_8007)">
          <path
            d="M9.99967 29.9997L6.16634 33.833C5.63856 34.3608 5.0344 34.4788 4.35384 34.1872C3.67329 33.8955 3.33301 33.3747 3.33301 32.6247V6.66634C3.33301 5.74967 3.6594 4.96495 4.31217 4.31217C4.96495 3.6594 5.74967 3.33301 6.66634 3.33301H33.333C34.2497 3.33301 35.0344 3.6594 35.6872 4.31217C36.34 4.96495 36.6663 5.74967 36.6663 6.66634V26.6663C36.6663 27.583 36.34 28.3677 35.6872 29.0205C35.0344 29.6733 34.2497 29.9997 33.333 29.9997H9.99967ZM8.58301 26.6663H33.333V6.66634H6.66634V28.5413L8.58301 26.6663ZM11.6663 23.333H21.6663C22.1386 23.333 22.5344 23.1733 22.8538 22.8538C23.1733 22.5344 23.333 22.1386 23.333 21.6663C23.333 21.1941 23.1733 20.7983 22.8538 20.4788C22.5344 20.1594 22.1386 19.9997 21.6663 19.9997H11.6663C11.1941 19.9997 10.7983 20.1594 10.4788 20.4788C10.1594 20.7983 9.99967 21.1941 9.99967 21.6663C9.99967 22.1386 10.1594 22.5344 10.4788 22.8538C10.7983 23.1733 11.1941 23.333 11.6663 23.333ZM11.6663 18.333H28.333C28.8052 18.333 29.2011 18.1733 29.5205 17.8538C29.84 17.5344 29.9997 17.1386 29.9997 16.6663C29.9997 16.1941 29.84 15.7983 29.5205 15.4788C29.2011 15.1594 28.8052 14.9997 28.333 14.9997H11.6663C11.1941 14.9997 10.7983 15.1594 10.4788 15.4788C10.1594 15.7983 9.99967 16.1941 9.99967 16.6663C9.99967 17.1386 10.1594 17.5344 10.4788 17.8538C10.7983 18.1733 11.1941 18.333 11.6663 18.333ZM11.6663 13.333H28.333C28.8052 13.333 29.2011 13.1733 29.5205 12.8538C29.84 12.5344 29.9997 12.1386 29.9997 11.6663C29.9997 11.1941 29.84 10.7983 29.5205 10.4788C29.2011 10.1594 28.8052 9.99967 28.333 9.99967H11.6663C11.1941 9.99967 10.7983 10.1594 10.4788 10.4788C10.1594 10.7983 9.99967 11.1941 9.99967 11.6663C9.99967 12.1386 10.1594 12.5344 10.4788 12.8538C10.7983 13.1733 11.1941 13.333 11.6663 13.333Z"
            fill="#F7941D"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_48_8007">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Inbox;
