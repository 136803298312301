import Activities from "./Activities";
import Todo from "./Todo";

function TodoAndActivities(props) {
  return (
    <div style={{ minWidth: "30%" }}>
      <Todo />
      <br />
      <Activities />
    </div>
  );
}

export default TodoAndActivities;
