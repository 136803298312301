import Icon from "../Icon";
import Image from "../Image";

function WithImage({ text, imageUrl, borderRadius = "50%", children, icon }) {
  return (
    <div className="d-flex align-center table-image">
      <div className="img-cnt">
        {icon ? (
          <Icon className={icon.className}>{icon.children}</Icon>
        ) : (
          <Image style={{ borderRadius }} src={imageUrl} />
        )}
      </div>
      {children || (
        <span className="d-block full-flex fs-14 f-400 text-blue">{text}</span>
      )}
    </div>
  );
}

export default WithImage;
