import Loader from "./Loader";

const InfoText = ({
  title,
  text,
  children,
  className = "",
  loading,
  ...props
}) => (
  <div
    {...props}
    className={`d-flex flex-column align-left summary-info ${className}`}
  >
    <span className="fs-12 raleway f-400 d-block">{title}</span>
    <span className="fs-14 d-block text-blue f-500">
      {loading ? <Loader /> : text}
    </span>
    {children}
  </div>
);

export default InfoText;
