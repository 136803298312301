import { useContext, useEffect } from "react";
import useWallet from "../../../hooks/api/useWallet";
import { formatDateTime } from "../../../utils/date";
import Status from "../../Status";
import Table from "../Table";
import View from "../View";
import WithImage from "../WithImage";
import ArrowDownIcon from "../../../assets/icons/ArrowDownIcon";
import ArrowUpIcon from "../../../assets/icons/ArrowUpIcon";
import usePagination from "../../../hooks/usePagination";
import AppContext from "../../../contexts/AppContext";

const tableHead = [
  {
    title: "#",
    target: "#",
    className: "w-50",
  },
  {
    title: "Title",
    target: ["type", "title", "description"],
    render: (values) => (
      <>
        <WithImage
          text={values[1]}
          icon={
            values[0] === "debit"
              ? {
                  className: "danger-light",
                  children: <ArrowDownIcon color="var(--alerts-danger)" />,
                }
              : {
                  className: "success-light",
                  children: <ArrowUpIcon color="var(--alerts-success)" />,
                }
          }
        />
        <br />
        <p style={{ maxWidth: 400, whiteSpace: "wrap" }}>{values[2]}</p>
      </>
    ),
  },
  {
    title: "Type",
    target: "type",
    render: (_, v = _ === "debit" ? "danger" : "info") => (
      <Status text={_} className={`${v}-light ${v}-text`} />
    ),
  },
];

function Transactions({ account, currency, refresh, ...props }) {
  const { getTransactions, getUserTransactions, transactions, loading } =
    useWallet();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });
  const { formatCurrency } = useContext(AppContext);

  useEffect(() => {
    if (account || (refresh && account))
      getUserTransactions(pageNumber, limit, account._id);
    else getTransactions(pageNumber, limit);
    // eslint-disable-next-line
  }, [pageNumber, limit, refresh]);
  return (
    <Table
      limit={limit}
      totalCount={200}
      onPaginate={setPageNumber}
      loading={loading}
      data={transactions}
      head={[
        ...tableHead,
        {
          title: "Amount",
          target: "amount",
          render: (v) => formatCurrency(v, currency),
        },
        {
          title: "Status",
          target: "status",
          render: (_, v = _ === "pending" ? "warn" : _) => (
            <Status text={_} className={`${v}-light ${v}-text`} />
          ),
        },
        {
          title: "Date",
          target: "createdAt",
          render: (v) => formatDateTime(v).date,
        },
        {
          title: "Action",
          target: "*",
          render: (v) => <View />,
        },
      ]}
      title="Transactions"
      {...props}
    />
  );
}

export default Transactions;
