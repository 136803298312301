const OptionIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_372_118449)">
        <path
          d="M8.00033 5.83329C8.73366 5.83329 9.33366 5.23329 9.33366 4.49996C9.33366 3.76663 8.73366 3.16663 8.00033 3.16663C7.26699 3.16663 6.66699 3.76663 6.66699 4.49996C6.66699 5.23329 7.26699 5.83329 8.00033 5.83329ZM8.00033 7.16663C7.26699 7.16663 6.66699 7.76663 6.66699 8.49996C6.66699 9.23329 7.26699 9.83329 8.00033 9.83329C8.73366 9.83329 9.33366 9.23329 9.33366 8.49996C9.33366 7.76663 8.73366 7.16663 8.00033 7.16663ZM8.00033 11.1666C7.26699 11.1666 6.66699 11.7666 6.66699 12.5C6.66699 13.2333 7.26699 13.8333 8.00033 13.8333C8.73366 13.8333 9.33366 13.2333 9.33366 12.5C9.33366 11.7666 8.73366 11.1666 8.00033 11.1666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_372_118449">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OptionIcon;
