import { getNestedValueFromObject } from "../../utils/object";

function Row({ cells = [], data, isHead, didx = 0 }) {
  // return (
  //   <tr className={"fs-14 tr-body text-blue f-400"}>
  //     {cells.map(({ target, render }, idx) => {
  //       const value =
  //         target === "#"
  //           ? (didx + 1).toString()
  //           : typeof target === "string"
  //           ? data[target]
  //           : target.map((f) => getNestedValueFromObject(data, f));
  //       return (
  //         <td key={idx}>
  //           {typeof render === "function" && !isHead
  //             ? render(value)
  //             : typeof value === "string" || typeof value === "number"
  //             ? value
  //             : value}
  //         </td>
  //       );
  //     })}
  //   </tr>
  // );

  return (
    <tr className={`fs-14 tr-body text-blue f-400`}>
      {cells.map(
        ({ render, className, target, separator = " ", title }, idx) => {
          const value =
            title && isHead
              ? title
              : target === "#"
              ? (didx + 1).toString()
              : target === "*"
              ? data
              : typeof target === "string"
              ? getNestedValueFromObject(data, target)
              : target.map((f) => getNestedValueFromObject(data, f));
          return (
            <td className={`${className}`} key={idx}>
              {typeof render === "function" && !isHead
                ? render(value)
                : typeof value === "string" || typeof value === "number"
                ? value
                : typeof value?.join === "function"
                ? value?.join(separator)
                : "-"}
            </td>
          );
        }
      )}
    </tr>
  );
}

export default Row;
