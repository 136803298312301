function Status({ text = "", className = "", light, ...props }) {
  return (
    <span
      {...props}
      className={`status fs-11 f-700 d-block ${
        light ? className + `-light ${className}-text` : className
      }`}
    >
      {text}
    </span>
  );
}

export default Status;
