function Icon({ children, className = "", ...props }) {
  return (
    <button
      {...props}
      className={` ${className} d-flex justify-center align-center icon`}
    >
      {children}
    </button>
  );
}

export default Icon;
