import { useState } from "react";
import Tab from "../../../components/Tab";

const tabs = [
  { title: "Rules & Regulations" },
  { title: "Guidelines" },
  { title: "Terms & Conditions" },
  { title: "Privacy Policy" },
  { title: "Employee Work Ethics" },
];

function Compliance(props) {
  const [selected, setSelected] = useState(tabs[0]);
  return (
    <div>
      <h2 className="fs-24 raleway f-700 text-primary">iCart Compliance</h2>
      <br />
      <p className="fs-14 text-blue f-400">
        View Guidelines, Rules and Regulations, and other instructions about the
        iCart
      </p>
      <br />
      <div className="display">
        <Tab onSwitch={setSelected} tabs={tabs} />
        <div className="display-content">
          <h2 className="fs-20 f-700 text-brown">{selected.title}</h2>
          <br />
          <p>
            Lorem ipsum dolor sit amet consectetur. Tristique molestie tristique
            viverra sapien non interdum. Ullamcorper tortor consequat quam sed
            elit ultricies consequat vel aliquet. Condimentum massa velit
            viverra porttitor. Arcu sociis id elementum aenean pellentesque
            convallis scelerisque. Dictum eget auctor semper massa tristique
            arcu ipsum duis mauris. Id nisl proin adipiscing dolor sem euismod.
            Vulputate non vulputate ornare arcu eu. Proin morbi tellus morbi
            rhoncus in proin dui. Mattis facilisi proin pellentesque vitae
            mauris lobortis egestas elit.
          </p>
          <h3>Et ultrices volutpat fermentum</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tristique molestie tristique
            viverra sapien non interdum. Ullamcorper tortor consequat quam sed
            elit ultricies consequat vel aliquet. Condimentum massa velit
            viverra porttitor. Arcu sociis id elementum aenean pellentesque
            convallis scelerisque. Dictum eget auctor semper massa tristique
            arcu ipsum duis mauris. Id nisl proin adipiscing dolor sem euismod.
            Vulputate non vulputate ornare arcu eu. Proin morbi tellus morbi
            rhoncus in proin dui. Mattis facilisi proin pellentesque vitae
            mauris lobortis egestas elit.
          </p>
          <h3>Et ultrices volutpat fermentum</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tristique molestie tristique
            viverra sapien non interdum. Ullamcorper tortor consequat quam sed
            elit ultricies consequat vel aliquet. Condimentum massa velit
            viverra porttitor. Arcu sociis id elementum aenean pellentesque
            convallis scelerisque. Dictum eget auctor semper massa tristique
            arcu ipsum duis mauris. Id nisl proin adipiscing dolor sem euismod.
            Vulputate non vulputate ornare arcu eu. Proin morbi tellus morbi
            rhoncus in proin dui. Mattis facilisi proin pellentesque vitae
            mauris lobortis egestas elit.
          </p>
          <h3>Et ultrices volutpat fermentum</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tristique molestie tristique
            viverra sapien non interdum. Ullamcorper tortor consequat quam sed
            elit ultricies consequat vel aliquet. Condimentum massa velit
            viverra porttitor. Arcu sociis id elementum aenean pellentesque
            convallis scelerisque. Dictum eget auctor semper massa tristique
            arcu ipsum duis mauris. Id nisl proin adipiscing dolor sem euismod.
            Vulputate non vulputate ornare arcu eu. Proin morbi tellus morbi
            rhoncus in proin dui. Mattis facilisi proin pellentesque vitae
            mauris lobortis egestas elit.
          </p>
          <h3>Et ultrices volutpat fermentum</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tristique molestie tristique
            viverra sapien non interdum. Ullamcorper tortor consequat quam sed
            elit ultricies consequat vel aliquet. Condimentum massa velit
            viverra porttitor. Arcu sociis id elementum aenean pellentesque
            convallis scelerisque. Dictum eget auctor semper massa tristique
            arcu ipsum duis mauris. Id nisl proin adipiscing dolor sem euismod.
            Vulputate non vulputate ornare arcu eu. Proin morbi tellus morbi
            rhoncus in proin dui. Mattis facilisi proin pellentesque vitae
            mauris lobortis egestas elit.
          </p>
          <h3>Et ultrices volutpat fermentum</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur. Tristique molestie tristique
            viverra sapien non interdum. Ullamcorper tortor consequat quam sed
            elit ultricies consequat vel aliquet. Condimentum massa velit
            viverra porttitor. Arcu sociis id elementum aenean pellentesque
            convallis scelerisque. Dictum eget auctor semper massa tristique
            arcu ipsum duis mauris. Id nisl proin adipiscing dolor sem euismod.
            Vulputate non vulputate ornare arcu eu. Proin morbi tellus morbi
            rhoncus in proin dui. Mattis facilisi proin pellentesque vitae
            mauris lobortis egestas elit.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Compliance;
