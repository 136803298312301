const CardIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.667 0.333313H2.33366C1.40866 0.333313 0.675326 1.07498 0.675326 1.99998L0.666992 12C0.666992 12.925 1.40866 13.6666 2.33366 13.6666H15.667C16.592 13.6666 17.3337 12.925 17.3337 12V1.99998C17.3337 1.07498 16.592 0.333313 15.667 0.333313ZM15.667 12H2.33366V6.99998H15.667V12ZM15.667 3.66665H2.33366V1.99998H15.667V3.66665Z"
        fill={color}
      />
    </svg>
  );
};

export default CardIcon;
