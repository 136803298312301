import Delete from "../../../assets/icons/Delete";
import SearchIcon from "../../../assets/icons/SearchIcon";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import Input from "../../../components/Input";
import InfoText from "../../../components/TextInfo";
import ItemCounter from "../../../components/ItemCounter";

const Summary = () => {
  return (
    <div className="bordered summary">
      <InfoText title="Order From:" text="iC150100230005FC" />
      <InfoText title="Location" text="Opposite Bolton White Garki." />
      <InfoText title="Your Order:">
        <table className="inner-table pd">
          <tr className="fs-14 f-400 text-blue">
            <td className="f-700 w-24">1</td>
            <td>Masa</td>
            <td className="fs-16 p-r-20 f-500 text-right">₦3,600.00</td>
            <td className="w-24">
              <Delete />
            </td>
          </tr>
          <tr className="fs-14 f-400 text-blue">
            <td className="f-700 w-24">1</td>
            <td>Noodles</td>
            <td className="fs-16 p-r-20 f-500 text-right">₦3,600.00</td>
            <td className="w-24">
              <Delete />
            </td>
          </tr>
        </table>
      </InfoText>
      <InfoText>
        <table className="inner-table">
          <tr className="fs-16 f-400 text-blue">
            <td className="text-blue fs-14">Subtotal</td>
            <td className="f-500 text-right">₦3,600.00</td>
          </tr>
          <tr className="fs-16 f-400 text-blue">
            <td className="text-blue fs-14">VAT (7.2%)</td>
            <td className="f-500 text-right">₦360.00</td>
          </tr>
        </table>
      </InfoText>
      <br />
      <div className="d-flex justify-between align-center">
        <span style={{ color: "#5B5B5C" }} className="fs-16 f-700 text-blue">
          Total
        </span>
        <span className="fs-24 f-700 success-text">₦5,160.00</span>
      </div>
      <br />
      <br />
      <Button title="Record as Paid via POS" />
      <div className="btns">
        <Button outline title="Paid via Cash" />
        <Button outline title="Paid via Transfer" />
      </div>
    </div>
  );
};

const Items = () => {
  return (
    <div className="items sales-items">
      <div className="d-flex justify-between align-center flex-wrap icart-info">
        <div className="itm-lst d-flex align-center">
          <div className="img-cart"></div>
          <span className="fs-16 full-flex text-brown f-600">
            iC150100230005FC - Garki Abuja, Nigeria.
          </span>
        </div>
        <div className="itm-lst">
          <Input
            placeholder="Search"
            rIcon={
              <Icon>
                <SearchIcon />
              </Icon>
            }
          />
        </div>
      </div>
      <br />
      <table className="inner-table pd">
        <ItemCounter />
        <ItemCounter />
        <ItemCounter />
        <ItemCounter />
      </table>
    </div>
  );
};

function RecordSale(props) {
  return (
    <div>
      <h2 className="fs-24 raleway f-700 text-primary">Record a Sale</h2>
      <br />
      <p className="fs-14 text-blue f-400">
        Record sales made with your iCart here.
      </p>
      <br />
      <div className="d-flex flex-wrap justify-bteween sales-input">
        <Items />
        <br />
        <Summary />
      </div>
    </div>
  );
}

export default RecordSale;
