import axiosClient from "../api";
import urls from "../api/urls";

export function addParamsToUrl(url, params) {
  const isRelativeUrl = !/^https?:\/\//i.test(url);
  const baseUrl = isRelativeUrl ? "http://placeholder.com" : url;

  const urlObject = new URL(url, baseUrl);

  Object.keys(params).forEach((key) => {
    if (params[key]) urlObject.searchParams.append(key, params[key]);
  });

  return isRelativeUrl
    ? urlObject.pathname + urlObject.search
    : urlObject.toString();
}

export const getImageUrl = (src) =>
  src?.includes("http") || src?.includes("file://")
    ? { src }
    : {
        src: axiosClient.getUri() + urls.files.baseUrl + src,
        crossOrigin: "anonymous",
      };
