import PlusIcon from "../assets/icons/PlusIcon";
import Button from "./Button";

const Group = ({ title, Element, items, setItems, AddElement, ...props }) => {
  const handleChange = (id, property, value) => {
    const updated = items.map((i) => {
      if (i.id === id) i[property] = value;
      return i;
    });
    setItems(updated);
  };
  const handleAdd = () =>
    setItems([
      ...items,
      { id: items.length ? items[items.length - 1].id + 1 : 1 },
    ]);

  return (
    <>
      {!AddElement && (
        <>
          {" "}
          <Button
            // style={{ marginLeft: "auto" }}
            width={50}
            onClick={handleAdd}
            rSvg={<PlusIcon color="var(--main-yellow)" />}
            className="bg-warn"
          />
          <br />
        </>
      )}
      <div className="list">
        {items.map((i, idx) => (
          <Element
            key={i._id || i.id || idx}
            {...i}
            {...props}
            items={items}
            handleChange={handleChange}
            remove={() => setItems(items.filter((_) => _.id !== i.id))}
          />
        ))}
      </div>
      <br />
      {AddElement && <AddElement onClick={handleAdd} />}
    </>
  );
};

export default Group;
