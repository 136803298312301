import { useEffect, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import Collapsable from "../Collapsable";

const Item = ({
  remove,
  id,
  conceptIngredient,
  instruction,
  quantity,
  handleChange,
  list = [],
  extras = [],
  conceptExtra,
  name,
}) => {
  const options = [
    ...list.map((_) => ({
      label: _.name,
      value: _._id,
      unit: _.unitOfMeasure,
    })),
    ...(extras.length ? extras : []),
  ];
  return (
    <Collapsable title={name} showInfo={false}>
      <Input
        value={name}
        onChange={(v) => handleChange(id, "name", v)}
        placeholder="Name"
      />
      <br />
      <div className="inputs">
        <Input
          select
          options={options}
          value={conceptIngredient || conceptExtra}
          placeholder="Ingredient"
          onChange={(v) => {
            if (extras.length) {
              if (extras.find((_) => _.value === v)) {
                handleChange(id, "conceptIngredient", null);
                handleChange(id, "conceptExtra", v);
              } else {
                handleChange(id, "conceptIngredient", v);
                handleChange(id, "conceptExtra", null);
              }
            } else {
              handleChange(id, "conceptIngredient", v);
            }
          }}
        />
        <Input
          value={quantity}
          onChange={(v) => handleChange(id, "quantity", v)}
          placeholder="Qauntity"
        />
      </div>
      <br />
      <Input
        value={instruction}
        onChange={(v) => handleChange(id, "instruction", v)}
        textarea
        placeholder="Instruction"
      />
      <br />
      <Button
        outline
        style={{ marginLeft: "auto" }}
        onClick={remove}
        borderColor="var(--alerts-danger)"
        title="Remove"
        color="danger-text"
        fontSize="fs-12"
        width={80}
        height={30}
      />
    </Collapsable>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function Varieties({ initialVarieties = [], list, extras, handleChange }) {
  const [varieties, setVarieties] = useState(initialVarieties);

  useEffect(() => {
    handleChange(varieties);
    // eslint-disable-next-line
  }, [varieties]);
  return (
    <div>
      <Group
        items={varieties}
        setItems={setVarieties}
        Element={Item}
        AddElement={AddButton}
        list={list}
        extras={extras}
      />
    </div>
  );
}

export default Varieties;
