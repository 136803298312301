const SendIcon = ({ color = "#ffffff" }) => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.674959 15.5L18.1666 8L0.674959 0.5L0.666626 6.33333L13.1666 8L0.666626 9.66667L0.674959 15.5Z"
      fill={color}
    />
  </svg>
);

export const SendIconTransformed = ({ color = "#fff" }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.621695 6.76213C0.186695 6.61713 0.182528 6.38296 0.630028 6.23379L16.5359 0.932127C16.9767 0.78546 17.2292 1.03213 17.1059 1.46379L12.5609 17.3688C12.4359 17.8096 12.1817 17.8246 11.995 17.4063L9.00003 10.6663L14 3.99963L7.33336 8.99963L0.621695 6.76213Z"
      fill={color}
    />
  </svg>
);

export default SendIcon;
