import FormattedInput from "../../../components/FormattedInput";
import Button from "../../../components/Button";
import { useContext, useEffect, useState } from "react";
import Icon from "../../../components/Icon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import Chv from "../../../assets/icons/Chv";
import Modal from "../../../components/Modal";
import OTPInput from "../../../components/PIN";
import useModal from "../../../hooks/useModal";
import AppContext from "../../../contexts/AppContext";
import Input from "../../../components/Input";
import useBanking from "../../../hooks/useBanking";
import UserIcon from "../../../assets/icons/UserIcon";
import Plus from "../../../assets/icons/Plus";
import Loader from "../../../components/Loader";
import useWallet from "../../../hooks/api/useWallet";
import { Link, useNavigate } from "react-router-dom";
import { path } from "../../../utils/path";
import { toast } from "react-toastify";

const RenderAccount = ({ isNew, selected, details, ...props }) => {
  return (
    <button
      {...props}
      style={selected ? { backgroundColor: "var(--black-tints-4)" } : {}}
      className="d-flex align-center account-btn"
    >
      <Icon>
        {isNew ? (
          <PlusIcon color="var(--yellow-tints-5)" />
        ) : (
          <UserIcon
            style={{ transform: "scale(0.8)" }}
            color="var(--yellow-tints-5)"
          />
        )}
      </Icon>
      <div>
        <span className="fs-14 text-left d-block f-700 text-brown">
          {isNew ? `Add account` : details.accountName}
        </span>
        {isNew ? null : (
          <span className="fs-12 text-left d-block text-brown">
            {details.bankName} ({details.accountNumber})
          </span>
        )}
      </div>
      <Icon style={{ marginLeft: "auto" }}>
        <Chv style={{ transform: "rotate(-90deg)" }} />
      </Icon>
    </button>
  );
};

const AddAccount = ({ cb }) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState(null);
  const { banks, getBanks, addBank, verifiedData, verifyAccount, loading } =
    useBanking();

  const handleSubmit = () => {
    if (!verifiedData) return;
    addBank(
      {
        accountNumber,
        bankCode: selectedBank.code,
        accountName: verifiedData.accountName,
        bankName: selectedBank.name,
      },
      cb
    );
  };

  useEffect(() => {
    if (accountNumber.length === 10 && selectedBank?.code) {
      verifyAccount(selectedBank.code, accountNumber);
    }
  }, [accountNumber, selectedBank]);

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <div>
      <Input
        options={banks.map((_) => ({
          label: _.name,
          value: JSON.stringify({ name: _.name, code: _.code }),
        }))}
        loading={loading}
        onChange={(v) => setSelectedBank(JSON.parse(v))}
        select
      />
      <br />
      <Input
        type="number"
        placeholder="Account Number"
        value={accountNumber}
        onChange={setAccountNumber}
      />
      <br />
      {verifiedData ? (
        <div className="card">
          <h2 className="fs-16">{verifiedData?.accountName}</h2>
        </div>
      ) : null}
      <br />
      <Button
        loading={loading}
        onClick={handleSubmit}
        title="Save"
        width={100}
      />
    </div>
  );
};

function Withdraw(props) {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState(null);
  const { getSavedAccounts, loading, savedAccounts } = useBanking();
  const { withdraw, loading: withdrawalLoading } = useWallet();
  const { isOpened, openModal, closeModal } = useModal();
  const {
    isOpened: addOpened,
    openModal: openAddModal,
    closeModal: closeAddModal,
  } = useModal();
  const { formatCurrency } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    getSavedAccounts();
  }, []);

  return (
    <div>
      <Modal isVisible={addOpened} closeModal={closeAddModal}>
        <h2 className="fs-24 raleway f-700 text-primary">
          Add Settlement Account
        </h2>
        <p className="text-brown">Add a beneficiary bank account.</p>
        <br />
        <AddAccount
          cb={async () => {
            getSavedAccounts();
            closeAddModal();
          }}
        />
      </Modal>
      <Modal isVisible={isOpened} closeModal={closeModal}>
        <h2 className="text-brown fs-32 inter">
          Send: {formatCurrency(amount)}
        </h2>
        <p className="fs-13">
          Enter your 4 digit transaction pin to confirm withdrawal of{" "}
          <strong>{formatCurrency(amount)}</strong> to{" "}
          <strong>
            {selectedAccount?.accountName} ({selectedAccount?.accountName} -{" "}
            {selectedAccount?.bankName})
          </strong>
        </p>
        <OTPInput
          onComplete={(pin) =>
            withdraw(
              {
                amount,
                pin,
                account: selectedAccount?._id,
              },
              () => {
                toast.info("Transaction Initiated!");
                navigate(-1);
              }
            )
          }
          loading={withdrawalLoading}
        />
        <br />
        <Link
          className="pin-link fs-13 text-brown f-700"
          to={path.wallet.pin.url}
        >
          Create/Change Pin
        </Link>
      </Modal>
      <h2 className="text-brown">Withdraw</h2>
      <br />
      <FormattedInput
        value={amount}
        placeholder="Enter amount"
        onChange={setAmount}
      />
      <br />
      <RenderAccount onClick={openAddModal} isNew />
      {savedAccounts.map((_, idx) => (
        <>
          <br />
          <RenderAccount
            selected={selectedAccount?._id === _._id}
            details={_}
            key={idx}
            onClick={() => {
              setSelectedAccount(_);
            }}
          />
          <br />
        </>
      ))}
      <br />
      <br />
      <Button
        loading={loading}
        disabled={!amount || !selectedAccount}
        onClick={openModal}
        title="Continue"
        width={150}
      />
    </div>
  );
}

export default Withdraw;
