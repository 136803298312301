const UserIcon = ({ color = "#ffffff", ...props }) => {
  return (
    <svg
      {...props}
      width="36"
      height="36"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#F7941D" />
      <circle cx="31.5" cy="20.5" r="11.5" fill="white" />
      <ellipse cx="32" cy="46" rx="19" ry="10" fill="white" />
      <path
        d="M31.5 9C28.45 9 25.5249 10.2116 23.3683 12.3683C21.2116 14.5249 20 17.45 20 20.5C20 23.55 21.2116 26.4751 23.3683 28.6317C25.5249 30.7884 28.45 32 31.5 32L31.5 20.5V9Z"
        fill="#FDEAD2"
      />
      <path
        d="M32 56C26.9609 56 22.1282 54.9464 18.565 53.0711C15.0018 51.1957 13 48.6522 13 46C13 43.3478 15.0018 40.8043 18.565 38.9289C22.1282 37.0536 26.9609 36 32 36V46V56Z"
        fill="#FDEAD2"
      />
    </svg>
  );
};

export default UserIcon;
