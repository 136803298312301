// import { useContext, useState } from "react";
// import Status from "../../../components/Status";
// import WithLink from "../../../components/table/WithLink";
// import InfoText from "../../../components/TextInfo";
// import { formatDateTime } from "../../../utils/date";
// import AppContext from "../../../contexts/AppContext";
// import Table from "../../../components/table/Table";
// import WithImage from "../../../components/table/WithImage";
// import Button from "../../../components/Button";
// import { useLocation } from "react-router-dom";
// import statuses from "../../../utils/statuses";
// import useOperations from "../../../hooks/api/useOperations";

// const tablehead = [
//   {
//     title: "#",
//     target: "#",
//   },
//   {
//     title: "Menu",
//     target: "item.name",
//     render: (v) => <WithImage text={v} />,
//   },
//   {
//     title: "Quantiy",
//     target: "quantity",
//   },
// ];

// function ViewOrder(props) {
//   const { acceptOrder, completeOrder, loading } = useOperations();
//   const { formatCurrency } = useContext(AppContext);
//   const { state } = useLocation();
//   const [status, setStatus] = useState(state.status);

//   return (
//     <div>
//       <div className="d-flex justify-between gap-10 flex-wrap material-info">
//         <InfoText
//           className="full-flex"
//           title="Customer Name:"
//           text={state.customerInformation.name}
//         />
//         <InfoText className="full-flex" title="Customer Phone:">
//           <WithLink text={state.customerInformation.phoneNumber} />
//         </InfoText>

//         <InfoText
//           className="full-flex"
//           title="Date:"
//           text={formatDateTime(state.createdAt).dateTime}
//         />
//         <InfoText
//           className="full-flex"
//           title="Total:"
//           text={formatCurrency(state.totalPrice)}
//         />
//         <InfoText className="full-flex" title="Balance:">
//           <Status className="danger" light text={formatCurrency(0)} />
//         </InfoText>
//         <InfoText className="full-flex" title="Status:">
//           <Status light className={statuses[status]} text={status} />
//         </InfoText>
//       </div>
//       <div className="d-flex align-end gap-10 justify-between">
//         <InfoText className="full-flex" title="Delivery:">
//           <WithLink text={state.deliveryTo.address} />
//         </InfoText>
//         <InfoText className="full-flex" title="Payment Status:">
//           <Status
//             light
//             text={state.paymentCompleted ? "paid" : "pending"}
//             className={statuses[state.paymentCompleted ? "paid" : "pending"]}
//           />
//         </InfoText>
//         <div className="d-flex gap-10">
//           <Button
//             loading={loading}
//             onClick={() =>
//               acceptOrder(state._id, () => setStatus("processing"))
//             }
//             borderRadius={4}
//             disabled={status !== "pending"}
//             height={35}
//             width={160}
//             fontSize="fs-12"
//             title="Accept Order"
//           />
//           <Button
//             loading={loading}
//             onClick={() =>
//               completeOrder(state._id, () => setStatus("completed"))
//             }
//             disabled={status !== "processing"}
//             borderRadius={4}
//             height={35}
//             width={160}
//             fontSize="fs-12"
//             bg="bg-success"
//             title="Complete Order"
//           />
//         </div>
//       </div>
//       <br />
//       <Table
//         title="Ordered Items"
//         hideFooter
//         data={state.items}
//         head={[
//           ...tablehead,
//           {
//             title: "Total",
//             target: ["priceStamp", "quantity"],
//             render: (v) => formatCurrency(v[0] * v[1]),
//           },
//         ]}
//       />
//     </div>
//   );
// }

// export default ViewOrder;

import { useContext, useState } from "react";
import Status from "../../../components/Status";
import WithLink from "../../../components/table/WithLink";
import InfoText from "../../../components/TextInfo";
import { formatDateTime } from "../../../utils/date";
import AppContext from "../../../contexts/AppContext";
import Table from "../../../components/table/Table";
import WithImage from "../../../components/table/WithImage";
import Button from "../../../components/Button";
import { useLocation } from "react-router-dom";
import statuses from "../../../utils/statuses";
import useOperations from "../../../hooks/api/useOperations";
import useModal from "../../../hooks/useModal";
import Reciept from "../../../components/Reciept";
import Modal from "../../../components/Modal";
import { Submit } from "../../../components/form";

const tablehead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Menu",
    target: ["item.name", "item.images"],
    render: (v) => (
      <WithImage imageUrl={v[1]?.length ? v[1][0] : null} text={v[0]} />
    ),
  },
  {
    title: "Variety",
    target: "variety",
  },
  {
    title: "Drink",
    target: "drink",
  },
  {
    title: "Quantiy",
    target: "quantity",
  },
];

function ViewOrder(props) {
  const { acceptOrder, completeOrder, loading } = useOperations();
  const { formatCurrency } = useContext(AppContext);
  const { state } = useLocation();
  const [status, setStatus] = useState(state?.status);
  const { isOpened, closeModal, openModal } = useModal();
  const {
    isOpened: showConfirm,
    closeModal: closeConfirm,
    openModal: openConfirm,
  } = useModal();

  return (
    <div>
      <Modal isVisible={showConfirm}>
        <h2 className="fs-24 raleway f-700 text-primary">Confirm Payment</h2>
        <br />
        <p>
          The order: <strong>{state.id}</strong> is awaiting payment. Click
          'Continue' to confirm the payment and accept the order.
        </p>
        <br />
        <div className="d-flex gap-10">
          <Button onClick={closeConfirm} width={100} title="Cancel" outline />
          <Button
            onClick={() => {
              acceptOrder(
                state._id,
                () => {
                  setStatus("processing");
                  closeConfirm();
                },
                { paymentCompleted: true }
              );
            }}
            loading={loading}
            title="Continue"
          />
        </div>
      </Modal>
      {isOpened ? <Reciept handleClose={closeModal} order={state} /> : null}
      {/* <p>{JSON.stringify(state)}</p> */}
      <div className="d-flex justify-between gap-10 flex-wrap material-info">
        <InfoText
          className="full-flex"
          title="Customer Name:"
          text={state.customerInformation.name}
        />
        <InfoText className="full-flex" title="Customer Phone:">
          <WithLink text={state.customerInformation.phoneNumber} />
        </InfoText>

        <InfoText
          className="full-flex"
          title="Date:"
          text={formatDateTime(state.createdAt).dateTime}
        />
        <InfoText
          className="full-flex"
          title="Total:"
          text={formatCurrency(state.totalPrice)}
        />
        <InfoText className="full-flex" title="Balance:">
          <Status className="danger" light text={formatCurrency(0)} />
        </InfoText>
        <InfoText className="full-flex" title="Status:">
          <Status light className={statuses[status]} text={status} />
        </InfoText>
      </div>
      <br />
      <div className="d-flex align-end gap-10 justify-between">
        <InfoText className="full-flex" title="Delivery:">
          <WithLink
            link={`https://www.google.com/maps?q=${state.deliveryTo.lat},${state.deliveryTo.lng}`}
            text={state.deliveryTo.address}
          />
        </InfoText>
        <InfoText className="full-flex" title="Rider:">
          <WithLink
            link={
              state.rider && typeof state.rider !== "string"
                ? `tel:${state.rider?.phoneNumber}`
                : "#"
            }
            text={
              state.rider && typeof state.rider !== "string"
                ? `${state.rider?.firstName} ${state.rider?.lastName} (${state.rider?.phoneNumber})`
                : "-"
            }
          />
        </InfoText>
        <InfoText className="full-flex" title="Payment Status:">
          <Status
            light
            text={state.paymentCompleted ? "paid" : "pending"}
            className={statuses[state.paymentCompleted ? "paid" : "pending"]}
          />
        </InfoText>
        <div className="d-flex gap-10">
          <Button
            loading={loading}
            onClick={() =>
              !state.paymentCompleted
                ? openConfirm()
                : acceptOrder(state._id, () => setStatus("processing"))
            }
            borderRadius={4}
            disabled={status !== "pending"}
            height={35}
            width={160}
            fontSize="fs-12"
            title="Accept Order"
          />
          <Button
            loading={loading}
            onClick={() =>
              completeOrder(state._id, () => setStatus("completed"))
            }
            disabled={status !== "processing"}
            borderRadius={4}
            height={35}
            width={160}
            fontSize="fs-12"
            bg="bg-success"
            title="Complete Order"
          />
        </div>
      </div>
      <br />
      <br />
      <Table
        title="Ordered Items"
        hideFooter
        data={state.items}
        btn1={{
          title: "Print Reciept",
          onClick: openModal,
        }}
        head={[
          ...tablehead,
          {
            title: "Total",
            target: ["priceStamp", "quantity"],
            render: (v) => formatCurrency(v[0] * v[1]),
          },
        ]}
      />
    </div>
  );
}

export default ViewOrder;
