import useAPI from "./useAPI";
import urls from "../../api/urls";
import { toast } from "react-toastify";
import { objectToFormData } from "../../utils/array";
import useProfile from "./useProfile";
import { useState } from "react";

const useStore = () => {
  const [supplyrequests, setSupplyRequests] = useState([]);
  const [supplyrequest, setSupplyRequest] = useState([]);
  const { post, loading, get, refreshToken } = useAPI();
  const { restoreUser, loading: loading2 } = useProfile();

  const getSupplyRequests = async () => {
    const { data, error } = await get(urls.store.requests.get);
    if (error) return;
    setSupplyRequests(data?.supplyRequests || []);
  };

  const getSupplyRequest = async (id) => {
    const { data, error } = await get(urls.store.requests.get + `/${id}`);
    if (error) return;
    setSupplyRequest(data.supplyRequest);
  };

  const registerStore = async (fdata, img, cb) => {
    fdata = objectToFormData(fdata);
    if (img) fdata.append("logo", img);
    const { data, error, message } = await post(urls.store.create, fdata);
    if (error || !data) return;
    toast.info(message);
    await refreshToken();
    await restoreUser();
    if (typeof cb === "function") cb();
  };
  return {
    getSupplyRequests,
    getSupplyRequest,
    registerStore,
    supplyrequests,
    supplyrequest,
    loading: loading || loading2,
  };
};

export default useStore;
