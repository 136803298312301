const getItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    if (item) return JSON.parse(item).value;
  } catch (error) {
    return null;
  }
};
const setItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify({ value }));
const removeItem = (key) => localStorage.removeItem(key);

const setTokens = ({
  accessToken,
  refreshToken,
  accessExpiry,
  refreshExpiry,
}) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("accessExpiry", accessExpiry);
  localStorage.setItem("refreshExpiry", refreshExpiry);
};

const store = {
  // Add or update a profile with its associated tokens in the profiles list
  setProfile: (profile, tokens) => {
    const profiles = store.getProfiles();
    console.log(profiles);
    // Find if profile already exists based on _id
    const existingIndex = profiles.findIndex(
      (entry) => entry.profile._id === profile._id
    );

    const profileEntry = { profile, tokens };

    if (existingIndex !== -1) {
      // If profile exists, update it
      profiles[existingIndex] = profileEntry;
    } else {
      // If profile does not exist, add it
      profiles.push(profileEntry);
    }

    // Save the updated profiles list back to localStorage
    localStorage.setItem("profiles", JSON.stringify(profiles));
    localStorage.setItem("profile", JSON.stringify(profile));
    setTokens(tokens);
  },

  // Retrieve the list of profiles
  getProfiles: () => {
    const profiles = localStorage.getItem("profiles");
    return profiles ? JSON.parse(profiles) : [];
  },
  getProfile: () => {
    const profile = localStorage.getItem("profile");
    return profile ? JSON.parse(profile) : null;
  },

  // Remove a profile by its _id
  removeProfile: (_id) => {
    const profiles = store.getProfiles();
    const updatedProfiles = profiles.filter(
      (entry) => entry.profile._id !== _id
    );

    // Save the updated profiles list back to localStorage
    localStorage.setItem("profiles", JSON.stringify(updatedProfiles));
    localStorage.removeItem("profile");
  },

  // Clear all profiles from localStorage
  clearProfiles: () => {
    localStorage.removeItem("profiles");
  },

  // Existing functions for managing tokens and other items
  setTokens,

  getTokens: () => ({
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    accessExpiry: new Date(localStorage.getItem("accessExpiry")),
    refreshExpiry: new Date(localStorage.getItem("refreshExpiry")),
  }),

  removeTokens: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessExpiry");
    localStorage.removeItem("refreshExpiry");
  },

  // Clear all items from localStorage
  removeAll: () => {
    const settings = getItem("settings") || {};
    const outlet = getItem("outlet");
    localStorage.clear();
    setItem("settings", settings);
    setItem("outlet", outlet);
  },

  // General-purpose localStorage handlers
  getItem,
  setItem,
  removeItem,
};

export default store;
