const BackArrowIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_343_21538)">
        <path
          d="M26.6663 14.6666H10.4397L17.893 7.21331L15.9997 5.33331L5.33301 16L15.9997 26.6666L17.8797 24.7866L10.4397 17.3333H26.6663V14.6666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_343_21538">
          <rect width="32" height="32" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BackArrowIcon;
