import "../css/components/nav.css";
import logo from "../assets/logo.png";
import logoMobile from "../assets/logo-mobile.png";
import { useContext, useEffect, useState } from "react";
import {
  creatorTabs,
  iCartOperatingTabs,
  navigations,
  supplierTabs,
} from "../constants/navigation/navigations";
import { Link, useLocation } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import AuthContext from "../contexts/AuthContext";
import { roles } from "../utils/vars";

// const creatorsnavs = [
//   navigations[0],
//   navigations[8],
//   navigations[navigations.length - 3],
//   navigations[navigations.length - 1],
// ];
// const staffnavs = [
//   navigations[0],
//   navigations[2],
//   navigations[8],
//   navigations[navigations.length - 3],
//   navigations[navigations.length - 1],
// ];

function NavBar(props) {
  const { isCollapsed, setIsCollapsed } = useContext(AppContext);
  const { settings, user } = useContext(AuthContext);
  // eslint-disable-next-line
  const [activeRoute, setActiveRoute] = useState(navigations[0].url);
  const toggleCollapsed = () => setIsCollapsed(!isCollapsed);

  const { pathname } = useLocation();

  useEffect(() => {
    setActiveRoute("/" + pathname.split("/")[1]);
  }, [pathname]);

  return (
    <nav className={`${isCollapsed ? "collapsed" : ""} d-flex flex-column`}>
      <button onClick={toggleCollapsed} className="logo-cnt">
        <img
          className="logo"
          src={isCollapsed ? logoMobile : logo}
          alt="iCart Africa"
        />
      </button>
      <div className="links">
        {(settings.isOperatingDevice
          ? iCartOperatingTabs
          : user.role === roles.creator
          ? creatorTabs
          : user.role === roles.supplier
          ? supplierTabs
          : []
        ).map(({ url, title, Icon }) => (
          <Link
            key={url}
            className={`d-flex align-center nav-link ${
              url === activeRoute ? "active-link" : ""
            }`}
            to={url}
          >
            {
              <Icon
                color={
                  url !== activeRoute
                    ? "var(--yellow-tints-4)"
                    : "var(--main-off-black)"
                }
              />
            }
            {!isCollapsed && (
              <span className="d-block f-400 fs-14">{title}</span>
            )}
          </Link>
        ))}
      </div>
    </nav>
  );
}

export default NavBar;
