function Delete(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_40_21057)">
        <path
          d="M6 19.2832C6 20.3832 6.9 21.2832 8 21.2832H16C17.1 21.2832 18 20.3832 18 19.2832V9.2832C18 8.1832 17.1 7.2832 16 7.2832H8C6.9 7.2832 6 8.1832 6 9.2832V19.2832ZM9 9.2832H15C15.55 9.2832 16 9.7332 16 10.2832V18.2832C16 18.8332 15.55 19.2832 15 19.2832H9C8.45 19.2832 8 18.8332 8 18.2832V10.2832C8 9.7332 8.45 9.2832 9 9.2832ZM15.5 4.2832L14.79 3.5732C14.61 3.3932 14.35 3.2832 14.09 3.2832H9.91C9.65 3.2832 9.39 3.3932 9.21 3.5732L8.5 4.2832H6C5.45 4.2832 5 4.7332 5 5.2832C5 5.8332 5.45 6.2832 6 6.2832H18C18.55 6.2832 19 5.8332 19 5.2832C19 4.7332 18.55 4.2832 18 4.2832H15.5Z"
          fill="#D90505"
        />
      </g>
      <defs>
        <clipPath id="clip0_40_21057">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.283691)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Delete;
