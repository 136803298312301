import * as Yup from "yup";

export const createBrandSchema = Yup.object().shape({
  name: Yup.string().required().label("Brand Name"),
  description: Yup.string().required().label("Description"),
});

export const createConceptBasic = Yup.object().shape({
  origin: Yup.string().required().label("Origin"),
  name: Yup.string().required().label("Brand Name"),
  bestServeTo: Yup.string().required().label("Best serve to"),
  description: Yup.string().required().label("Description"),
});

export const createStoreSchema = Yup.object().shape({
  storeName: Yup.string().required().label("Store Name"),
  city: Yup.string().required().label("City"),
});
