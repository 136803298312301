import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const axiosClient = axios.create({
  baseURL: baseURL,
  // timeout: 10000,
});

// axiosClient.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("user-access-token");
//     if (token) {
//       config.headers["x-access-token"] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default axiosClient;
