import { useEffect, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import WindowInfoText from "../WindowInfoText";

const Item = ({
  remove,
  id,
  list = [],
  conceptMachine,
  quantity,
  handleChange,
}) => {
  return (
    <>
      <div className="submit-menu-card">
        <div className="inputs">
          <Input
            select
            value={conceptMachine}
            options={list.map((_) => ({ label: _.name, value: _._id }))}
            placeholder="Machine"
            onChange={(v) => handleChange(id, "conceptMachine", v)}
          />
          <Input
            type="number"
            value={quantity}
            placeholder="Quantity"
            onChange={(v) => handleChange(id, "quantity", v)}
          />
        </div>
        <br />
        <Button
          outline
          style={{ marginLeft: "auto" }}
          onClick={remove}
          borderColor="var(--alerts-danger)"
          title="Remove"
          color="danger-text"
          fontSize="fs-12"
          width={80}
          height={30}
        />
      </div>
      <br />
    </>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function AddMachines({ label, handleChange, machineries = [], list }) {
  const [machines, setMachines] = useState(machineries);
  useEffect(() => {
    handleChange(machines);
    // eslint-disable-next-line
  }, [machines]);
  return (
    <div>
      <WindowInfoText label={label} />
      <br />
      <Group
        items={machines}
        setItems={setMachines}
        Element={Item}
        AddElement={AddButton}
        list={list}
      />
    </div>
  );
}

export default AddMachines;
