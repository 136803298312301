function Video(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_52_8012)">
        <mask
          id="mask0_52_8012"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <rect width="40" height="40" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_52_8012)">
          <path
            d="M17.1247 26.667L26.4163 20.7087C26.6663 20.542 26.7913 20.3059 26.7913 20.0003C26.7913 19.6948 26.6663 19.4587 26.4163 19.292L17.1247 13.3337C16.8469 13.1392 16.5622 13.1253 16.2705 13.292C15.9788 13.4587 15.833 13.7087 15.833 14.042V25.9587C15.833 26.292 15.9788 26.542 16.2705 26.7087C16.5622 26.8753 16.8469 26.8614 17.1247 26.667ZM6.66634 33.3337C5.74967 33.3337 4.96495 33.0073 4.31217 32.3545C3.6594 31.7017 3.33301 30.917 3.33301 30.0003V10.0003C3.33301 9.08366 3.6594 8.29894 4.31217 7.64616C4.96495 6.99338 5.74967 6.66699 6.66634 6.66699H33.333C34.2497 6.66699 35.0344 6.99338 35.6872 7.64616C36.34 8.29894 36.6663 9.08366 36.6663 10.0003V30.0003C36.6663 30.917 36.34 31.7017 35.6872 32.3545C35.0344 33.0073 34.2497 33.3337 33.333 33.3337H6.66634ZM6.66634 30.0003H33.333V10.0003H6.66634V30.0003Z"
            fill="#F7941D"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_52_8012">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Video;
