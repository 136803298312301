function PlusIcon({ color = "#ffffff" }) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 0.5H1.66667C0.741667 0.5 0 1.25 0 2.16667V13.8333C0 14.75 0.741667 15.5 1.66667 15.5H13.3333C14.25 15.5 15 14.75 15 13.8333V2.16667C15 1.25 14.25 0.5 13.3333 0.5ZM13.3333 13.8333H1.66667V2.16667H13.3333V13.8333ZM6.66667 12.1667H8.33333V8.83333H11.6667V7.16667H8.33333V3.83333H6.66667V7.16667H3.33333V8.83333H6.66667V12.1667Z"
        fill={color}
      />
    </svg>
  );
}

export default PlusIcon;
