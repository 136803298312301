import { useContext, useState } from "react";
import useWindow from "../hooks/useWindow";
import AppContext from "../contexts/AppContext";
import FilePicker from "./FilePicker";
import { Form, FormInput, Submit } from "./form";
import { createBrandSchema } from "../utils/validators/creators";
import useBrand from "../hooks/api/useBrand";
import AuthContext from "../contexts/AuthContext";

function RegisterBrand() {
  const [logo, setLogo] = useState(null);
  const { isCollapsed } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { registerBrand, loading } = useBrand();
  const { width } = useWindow();

  if (user.brand) return null;

  return (
    <div
      className="full-flex d-flex justify-center align-center"
      style={{
        width: width - (isCollapsed ? 80 : 230),
        height: "100%",
        right: 0,
        top: 0,
        backgroundColor: "#FFF9F2",
        position: "fixed",
        padding: 20,
        overflowY: "auto",
        zIndex: 10,
      }}
    >
      <Form
        validationSchema={createBrandSchema}
        // onSubmit={setBrand}
        onSubmit={(v) => registerBrand(v, logo)}
        initialValues={{ name: "", description: "" }}
      >
        <div style={{ maxWidth: 450, width: "100%" }}>
          <h2 className="raleway fs-24 f-700 text-primary">
            Register Your Brand
          </h2>
          <br />
          <FormInput
            name="name"
            placeHolderBg="#FFF9F2"
            placeholder="Brand Name"
          />
          <br />
          <FormInput
            name="description"
            placeHolderBg="#FFF9F2"
            textarea
            placeholder="Brand Description"
          />
          <br />
          <FilePicker onSelect={setLogo} height={200} />
          <br />
          <Submit loading={loading} title="Save & Proceed" />
        </div>
      </Form>
    </div>
  );
}

export default RegisterBrand;
