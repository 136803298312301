import React, { useState, useRef } from "react";

const OTPInput = ({
  length = 4,
  loading,
  style,
  onChange,
  onComplete,
  placeholder,
}) => {
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputs = useRef([]);

  const handleNumberPress = (num) => {
    const newOtp = [...otp];
    const nextIndex = newOtp.findIndex((val) => val === "");

    if (nextIndex !== -1) {
      newOtp[nextIndex] = num;
      setOtp(newOtp);
      onChange && onChange(newOtp.join(""));

      if (nextIndex === length - 1) {
        onComplete && onComplete(newOtp.join(""));
      }
    }
  };

  const handleBackspace = () => {
    const newOtp = [...otp];
    const lastIndex = newOtp.lastIndexOf(newOtp.find((val) => val !== ""));

    if (lastIndex !== -1) {
      newOtp[lastIndex] = "";
      setOtp(newOtp);
      onChange && onChange(newOtp.join(""));
    }
  };

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={styles.otpContainer}>
        {otp.map((value, index) => (
          <div key={index} style={styles.otpInput}>
            <span style={styles.otpText}>{value ? "*" : null}</span>
          </div>
        ))}
      </div>
      {placeholder ? <p style={styles.placeholder}>{placeholder}</p> : null}
      <div style={styles.keyboardContainer}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
          <button
            disabled={loading}
            key={num}
            style={styles.key}
            onClick={() => handleNumberPress(num.toString())}
          >
            {num}
          </button>
        ))}
        <button
          disabled={loading}
          style={{ ...styles.key, ...styles.zero }}
          onClick={() => handleNumberPress("0")}
        >
          0
        </button>
        <button disabled={loading} style={styles.key} onClick={handleBackspace}>
          {loading ? <div style={styles.loader}>Loading...</div> : "⌫"}
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  otpContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
  otpInput: {
    width: "40px",
    height: "50px",
    margin: "5px",
    borderBottom: "2px solid #ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  otpText: {
    fontSize: "20px",
  },
  keyboardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
  },
  key: {
    width: "30%",
    padding: "15px",
    margin: "5px",
    backgroundColor: "#f0f0f0",
    textAlign: "center",
    borderRadius: "5px",
    cursor: "pointer",
    border: "none",
    fontSize: "18px",
  },
  zero: {
    width: "63%",
  },
  placeholder: {
    marginBottom: "15px",
  },
  loader: {
    height: "20px",
  },
};

export default OTPInput;
