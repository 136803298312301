import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().required().label("Email").email(),
  password: Yup.string().required().label("Password"),
});

export const roleSchema = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  description: Yup.string().required().label("Description"),
});

export const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().required().label("Email").email(),
});

export const completeResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required().label("Password"),
  password2: Yup.string().required().label("Password"),
});

export const resetOTPSchema = Yup.object().shape({
  otp: Yup.number().max(99999).min(10000).required().label("OTP"),
});

export const registerSchema = Yup.object().shape({
  email: Yup.string().required().label("Email").email(),
  password: Yup.string().required().label("Password"),
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phoneNumber: Yup.string().required().label("Phone Number"),
});
