const LockIcon = ({ color = "#F7941D" }) => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1673 6.66634V4.99967C11.1673 2.69967 9.30065 0.833008 7.00065 0.833008C4.70065 0.833008 2.83398 2.69967 2.83398 4.99967V6.66634H0.333984V18.333H13.6673V6.66634H11.1673ZM4.50065 4.99967C4.50065 3.61634 5.61732 2.49967 7.00065 2.49967C8.38398 2.49967 9.50065 3.61634 9.50065 4.99967V6.66634H4.50065V4.99967ZM12.0007 16.6663H2.00065V8.33301H12.0007V16.6663ZM7.00065 14.1663C7.91732 14.1663 8.66732 13.4163 8.66732 12.4997C8.66732 11.583 7.91732 10.833 7.00065 10.833C6.08398 10.833 5.33398 11.583 5.33398 12.4997C5.33398 13.4163 6.08398 14.1663 7.00065 14.1663Z"
        fill={color}
      />
    </svg>
  );
};

export default LockIcon;
