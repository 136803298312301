import Dashboard from "../pages/app/operators/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import RecordSale from "../pages/app/operators/RecordSale";
import ViewOrder from "../pages/app/operators/ViewOrder";
import Orders from "../pages/app/operators/Orders";

function Operators(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
      <Route
        path={path.iCartOperating.onlineOrders.details.path}
        element={<ViewOrder />}
      />
      <Route
        path={path.iCartOperating.onlineOrders.path}
        element={<Orders />}
      />
      <Route
        path={path.iCartOperating.recordSale.path}
        element={<RecordSale />}
      />
    </Routes>
  );
}

export default Operators;
