import Card from "../../../components/Card";
import Revenue from "../../../components/charts/Revenue";
import TodoAndActivities from "../../../components/TodoAndActivities";
import OnlineOrders from "../../../components/table/withdata/OnlineOrders";
// import RecentSalesOnICart from "../../../components/table/withdata/RecentSalesOnICart";
// import RecentSupplyToICart from "../../../components/table/withdata/RecentSupplyToICart";

function Dashboard(props) {
  return (
    <div>
      <div className="d-flex tab-column flex-wrap gap-10">
        <div className="full-flex">
          <div className="cards d-flex">
            <Card subTitle="Total Sales" title={0} />
            <Card className="warn-light" title={0} subTitle="New Orders" />
            <Card
              className="success-light"
              title={0}
              subTitle="Completed Orders"
            />
            <Card
              className="info-light"
              title={0}
              subTitle="Delivered Orders"
            />
          </div>
          <br />
          <Revenue random={false} title="Sales" />
        </div>
        <TodoAndActivities />
      </div>
      <br />
      <OnlineOrders hideFooter />
      <br />
      {/* <div className="d-flex gap-10 flex-wrap">
        <div style={{ width: "50%" }} className="full-flex">
          <RecentSupplyToICart hideFooter={true} width={0.49} />
        </div>
        <div style={{ width: "50%" }} className="full-flex">
          <RecentSalesOnICart hideFooter={true} width={0.49} />
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;
