const OrdersIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3335 15.6667V4.91667L0.708496 1.37501L2.2085 0.666672L4.16683 4.87501H13.8335L15.7918 0.666672L17.2918 1.37501L15.6668 4.91667V15.6667H2.3335ZM7.3335 9.83334H10.6668C10.9029 9.83334 11.1009 9.75348 11.2606 9.59376C11.4203 9.43403 11.5002 9.23612 11.5002 9C11.5002 8.76389 11.4203 8.56598 11.2606 8.40626C11.1009 8.24653 10.9029 8.16667 10.6668 8.16667H7.3335C7.09738 8.16667 6.89947 8.24653 6.73975 8.40626C6.58002 8.56598 6.50016 8.76389 6.50016 9C6.50016 9.23612 6.58002 9.43403 6.73975 9.59376C6.89947 9.75348 7.09738 9.83334 7.3335 9.83334ZM4.00016 14H14.0002V6.54167H4.00016V14Z"
        fill={color}
      />
    </svg>
  );
};

export default OrdersIcon;
