import { useEffect, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import WindowInfoText from "../WindowInfoText";

const Item = ({ remove, id, instruction, handleChange, ...props }) => {
  return (
    <>
      <div className="submit-menu-card">
        <Input
          placeholder="Instruction"
          {...props}
          value={instruction}
          onChange={(v) => handleChange(id, "instruction", v)}
        />
        <br />
        <Button
          outline
          style={{ marginLeft: "auto" }}
          onClick={remove}
          borderColor="var(--alerts-danger)"
          title="Remove"
          color="danger-text"
          fontSize="fs-12"
          width={80}
          height={30}
        />
      </div>
      <br />
    </>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function PrepInstruction({
  label = "",
  handleChange,
  initialInstructions = [],
  ...props
}) {
  const [instructions, setInstructions] = useState(initialInstructions);

  useEffect(() => {
    handleChange(instructions);
    // eslint-disable-next-line
  }, [instructions]);

  return (
    <div>
      <WindowInfoText label={label} />
      <br />
      <Group
        {...props}
        items={instructions}
        setItems={setInstructions}
        Element={Item}
        AddElement={AddButton}
      />
    </div>
  );
}

export default PrepInstruction;
