const LogoutIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1665 3.83333L11.9915 5.00833L14.1415 7.16667H5.6665V8.83333H14.1415L11.9915 10.9833L13.1665 12.1667L17.3332 8L13.1665 3.83333ZM2.33317 2.16667H8.99984V0.5H2.33317C1.4165 0.5 0.666504 1.25 0.666504 2.16667V13.8333C0.666504 14.75 1.4165 15.5 2.33317 15.5H8.99984V13.8333H2.33317V2.16667Z"
        fill={color}
      />
    </svg>
  );
};

export default LogoutIcon;
