const InventoryIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3 4.5L13.8 7H2.2L2.7 4.5H13.3ZM14.6667 0.333334H1.33333V2H14.6667V0.333334ZM14.6667 2.83333H1.33333L0.5 7V8.66667H1.33333V13.6667H9.66667V8.66667H13V13.6667H14.6667V8.66667H15.5V7L14.6667 2.83333ZM3 12V8.66667H8V12H3Z"
        fill={color}
      />
    </svg>
  );
};

export default InventoryIcon;
