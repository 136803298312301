import Card from "../../components/Card";
import { path } from "../../utils/path";

function BaseHome(props) {
  return (
    <div>
      <div className="cards flex-column d-flex">
        {/* <Card
          className="warn-light"
          subTitle="iCart Subscription"
          title="Subscribe for iCart"
        /> */}
        <Card
          formatTitle={false}
          to={path.creators.url}
          className="warn-light"
          subTitle="iCart Creators"
          title="Become a Creator on iCart"
        />
        <Card
          formatTitle={false}
          to={path.suppliers.url}
          className="warn-light"
          subTitle="iCart Suplliers"
          title="Become a Supplier on iCart"
        />
        {/* <Card
          to={path.iCartOperating.url}
          className="warn-light"
          subTitle="iCart Operators"
          title="Become an Operator on iCart"
        /> */}
      </div>
    </div>
  );
}

export default BaseHome;
