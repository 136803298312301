import AuthContext from "../../contexts/AuthContext";
import store from "../../utils/store.js";
import urls from "../../api/urls.js";
import { useContext } from "react";
import useAPI from "./useAPI";
import { toast } from "react-toastify";
import useProfile from "./useProfile";
import { roles } from "../../utils/vars";

const useAuth = () => {
  const { post, patch, loading, errorResponse } = useAPI();
  const { setUser } = useContext(AuthContext);
  const { restoreLocalUser } = useProfile();

  // Register a new user
  const register = async (userData, location = "/") => {
    const { error, data } = await post(urls.auth.signup, userData);
    if (error) return;
    const { tokens, profile } = data;
    store.setProfile(profile, tokens);
    setUser(profile);
    window.location = location;
  };

  // Request OTP to reset password
  const requestResetPassword = async (fdata, cb) => {
    const { error, data } = await post(urls.auth.resetPassword, fdata);
    if (error) return;
    toast.info(data.message);
    if (typeof cb === "function") cb();
  };

  // Reset password with OTP
  const resetPassword = async (fdata, cb) => {
    const { error, data } = await patch(urls.auth.resetPassword, fdata);
    if (error) return;
    toast.info(data.message);
    if (typeof cb === "function") cb();
  };

  // Login user
  const login = async (credentials) => {
    const { error, data } = await post(urls.auth.login, {
      ...credentials,
      kioskId: store.getItem("outlet")?._id,
    });
    if (error) return;
    const { tokens, profile, brand, staff } = data;
    if (
      (!Object.values(roles).includes(profile.role) &&
        profile.role !== "operator" &&
        !staff) ||
      (profile.role === "operator" && !store.getItem("outlet"))
    )
      return toast.error("Account not supported!");
    store.setProfile({ ...profile, brand, staff }, tokens);
    window.location = "/";
    return data;
  };

  // Refresh user access token
  const refreshAccessToken = async () => {
    const { refreshToken } = store.getTokens();
    if (!refreshToken) return;
    const { error, data } = await post(
      urls.auth.access,
      {},
      {
        headers: {
          "x-access-token": refreshToken,
        },
      }
    );
    if (error) return;
    const { tokens } = data;
    store.setTokens(tokens);
    return data;
  };

  const logout = () => {
    const user = restoreLocalUser();
    if (!user) return;
    store.removeProfile(user._id);
    store.removeTokens();
    setUser(null);
  };

  return {
    requestResetPassword,
    refreshAccessToken,
    errorResponse,
    resetPassword,
    register,
    logout,
    login,
    loading,
  };
};

export default useAuth;
