function Plus({ color = "#fff" }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66732 5.66683H5.66732V9.66683H4.33398V5.66683H0.333984V4.3335H4.33398V0.333496H5.66732V4.3335H9.66732V5.66683Z"
        fill={color}
      />
    </svg>
  );
}

export default Plus;
