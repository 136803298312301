const BarsIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_703)">
        <path
          d="M3.90918 7.4636H6.091V14.5909H3.90918V7.4636ZM7.98191 4.40906H10.0183V14.5909H7.98191V4.40906ZM12.0546 10.2272H14.091V14.5909H12.0546V10.2272Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_703">
          <rect
            width="17.4545"
            height="17.4545"
            fill={color}
            transform="translate(0.272949 0.772705)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BarsIcon;
