import { useEffect, useState } from "react";

function useBounceQuery({ search, setItems }) {
  const [bouncedQuery, setDebouncedQuery] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (!bouncedQuery) {
      if (typeof setItems === "function") setItems([]);
      return;
    }
    if (typeof search === "function") search(bouncedQuery);
  }, [bouncedQuery]);

  return { setQuery, query };
}

export default useBounceQuery;
