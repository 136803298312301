import "../css/components/modal.css";

function Modal({ isVisible, children, className = "", closeModal }) {
  if (!isVisible) return null;
  return (
    <div
      className={`modal inter d-flex justify-center align-center ${className}`}
    >
      <button onClick={closeModal} className="close-modal"></button>
      <div className="m-content">{children}</div>
    </div>
  );
}

export default Modal;
