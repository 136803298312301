import React, { useEffect, useState } from "react";
import useSupply from "../../../hooks/api/useSupply";
import Table from "../../../components/table/Table";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import Status from "../../../components/Status";
import { baseStatuses } from "../../../utils/statuses";
import { formatNumber } from "../../../utils/numbers";
import WithImage from "../../../components/table/WithImage";
import InfoText from "../../../components/TextInfo";
import Loader from "../../../components/Loader";
import NotFound from "../../../components/NotFound";
import { formatDateTime } from "../../../utils/date";
import WithLink from "../../../components/table/WithLink";
import useQuery from "../../../hooks/useQuery";
import { useNavigate } from "react-router-dom";

export default function ViewRequest() {
  const { acceptSupplyRequest, loading } = useSupply();
  const { supplyrequest, getSupplyRequest, loading: getLoading } = useSupply();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const query = useQuery();
  const navigate = useNavigate();

  const tableHead = [
    {
      title: "#",
      target: "#",
    },
    {
      title: "Name",
      target: ["material.name", "material.imageUrl"],
      render: (v) => <WithImage imageUrl={v[1]} text={v[0]} />,
    },
    {
      title: "Status",
      target: "status",
      render: (v) => {
        return <Status text={v} light className={baseStatuses[v]} />;
      },
    },
    {
      title: "Quantity",
      target: ["requestedQuantity", "supplyUnit"],
      render: (v) => formatNumber(v[0], "") + " " + `(${v[1]})`,
    },
    {
      title: "Priority",
      target: "priority",
      render: (v) => {
        return <Status text={v} light className={baseStatuses[v]} />;
      },
    },
    {
      title: "Action",
      target: ["status", "_id"],
      render: (i) => (
        <div className="d-inline-flex gap-10">
          <Button
            disabled={i[0] != "requested"}
            width={130}
            height={40}
            fontSize="fs-12"
            className="bg-info"
            title="Accept Supply"
            onClick={() =>
              setSelectedRequest(
                supplyrequest?.items?.find((item) => item._id === i[1])
              )
            }
          />
          <Button
            disabled
            width={130}
            height={40}
            fontSize="fs-12"
            className="bg-success"
            title={"Mark as Sent"}
          />
        </div>
      ),
    },
  ];

  const handleSupplyRequest = async () => {
    const fdata = {
      items: [
        {
          id: selectedRequest._id,
          // responseId: "66ee85d4864f97c7abd56073", //for updating response
          cost: selectedRequest?.amount,
        },
      ],
    };
    await acceptSupplyRequest(query.get("id"), fdata, () => {
      setSelectedRequest(null);
      getSupplyRequest(query.get("id"));
    });
  };

  useEffect(() => {
    if (query.get("id")) getSupplyRequest(query.get("id"));
    else navigate(-1);

    // eslint-disable-next-line
  }, []);

  if (getLoading)
    return (
      <div
        style={{ height: "100%" }}
        className="d-flex align-center justify-center"
      >
        <Loader loading />
      </div>
    );
  if (!supplyrequest) return <NotFound />;

  return (
    <div>
      <h2 className="text-primary">Request Details</h2>
      <br />
      <div className="d-flex flex-wrap gap-10">
        <InfoText
          className="full-flex"
          title="Sent By:"
          text={`${supplyrequest?.requestedBy?.firstName} ${supplyrequest?.requestedBy?.lastName}`}
        />
        <InfoText
          className="full-flex"
          title="Requested From:"
          text={supplyrequest?.origin?.name}
        />
        <InfoText className="full-flex" title="Delivery Address:">
          <WithLink
            link={`https://www.google.com/maps/search/?api=1&query=${supplyrequest?.origin?.location?.lat},${supplyrequest?.origin?.location?.lng}`}
            text={supplyrequest?.origin?.location?.label}
          />
        </InfoText>
        <InfoText
          className="full-flex"
          title="Requested Time:"
          text={formatDateTime(supplyrequest?.createdAt).dateTime}
        />
      </div>
      <br />
      <div>
        <Table
          loading={loading}
          data={supplyrequest?.items || []}
          head={tableHead}
          title="Items"
        />
        <Modal isVisible={selectedRequest ? true : false}>
          <div className="g-10 w-full">
            <h2 className="text-primary fw-700 fs-24 raleway">
              Accept Request
            </h2>
            <br />

            <p className="fs-14">
              You are about to accept request for items from the store. Select
              the items and input the prices for them.
            </p>
            <br />
            <Input
              placeholder={"Item Name"}
              value={selectedRequest?.material.name}
              contentEditable={false}
            />
            <br />
            <div className="d-flex gap-10 w-full">
              <Input
                placeholder={"Requested Quantity"}
                type="number"
                contentEditable={false}
                value={selectedRequest?.requestedQuantity}
              />
              <Input
                select
                placeholder={"Unit of Measure"}
                options={[
                  {
                    label: selectedRequest?.supplyUnit,
                    value: selectedRequest?.supplyUnit,
                  },
                ]}
                value={selectedRequest?.supplyUnit}
              />
            </div>
            <br />
            <br />
            <Input
              value={selectedRequest?.amount}
              onChange={(amount) =>
                setSelectedRequest({ ...selectedRequest, amount })
              }
              placeholder={`${selectedRequest?.material?.name} Price`}
              type="number"
            />
            <br />
            <div className="d-flex gap-10 w-full">
              <Button
                outline
                title={"Cancel"}
                onClick={() => setSelectedRequest(null)}
              />
              <Button
                disabled={!selectedRequest?.amount}
                title={"Send Response"}
                onClick={handleSupplyRequest}
                loading={loading}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
