import { Bar, Line } from "react-chartjs-2";
import { options } from ".";
import { useState } from "react";
import "../../css/components/graph.css";
import { COLORS } from "../../utils/colors";
import Input from "../Input";
import useWindow from "../../hooks/useWindow";
import { months } from "../../utils/date";

const graphType = { line: "line", bar: "bar" };

function Revenue({
  className = "",
  title,
  height = "fit-content",
  random = true,
  data = {
    labels: months.map((_) => _.slice(0, 3)),
    datasets: [
      {
        label: "",
        data: months.map((_) =>
          random ? (Math.random() * 10000).toFixed(0) : 0
        ),
        // data: months.map((_) => (Math.random() * 10000).toFixed(0)),
        borderColor: COLORS.MAIN.BLUE,
        backgroundColor: COLORS.MAIN.BLUE,
      },
    ],
  },
}) {
  const [type, setType] = useState(graphType.bar);
  const Graph = type === graphType.bar ? Bar : Line;
  const { width } = useWindow();
  if (width <= 560) return null;
  return (
    <div
      style={{ width: "100%", height }}
      className={`stat card rev-stat ${className}`}
    >
      <div className="d-flex inp-rev">
        <h4 className="text-primary raleway fs-20 f-600">{title} Chart</h4>
        <div className="inputs">
          <Input
            height={40}
            select
            placeholder="Timing"
            options={[
              {
                value: "monthly",
                label: "Monthly",
              },
              {
                value: "yearly",
                label: "Yearly",
              },
            ]}
          />
          <Input
            height={40}
            select
            onChange={setType}
            placeholder="Type"
            options={[
              {
                value: "line",
                label: "Line",
              },
              {
                value: "bar",
                label: "Bar",
              },
            ]}
          />
        </div>
      </div>
      <div className="g-cnt">
        <Graph
          options={{
            ...options,
            responsive: true,
            plugins: {
              ...options.plugins,
              legend: {
                display: false,
              },
            },
          }}
          data={data}
        />
      </div>
    </div>
  );
}

export default Revenue;
