import { useContext, useRef, useState } from "react";
import Row from "./Row";
import AngleLeftIcon from "../../assets/icons/AngleLeftIcon";
import AngleRightIcon from "../../assets/icons/AngleRightIcon";
import ArrowEndLeftIcon from "../../assets/icons/ArrowEndLeftIcon";
import ArrowEndRightIcon from "../../assets/icons/ArrowEndRightIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import "../../css/components/table.css";
import useWindow from "../../hooks/useWindow";
import { formFrom } from "../../utils/array";
import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";
import Loader from "../Loader";
import AppContext from "../../contexts/AppContext";

function Table({
  limit = 0,
  initialPage = 1,
  totalCount = 0,
  loading = false,
  onPaginate = console.log,
  title = "Table Title",
  onSearch,
  btn1,
  btn2,
  head = [],
  search,
  data = [],
  maxWidth = 1,
  hideFooter = false,
}) {
  const { isCollapsed } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const scrollRef = useRef(null);
  const { width } = useWindow();

  const tableWidth = width - 40 - (isCollapsed ? 80 : 230);

  const paginateNext = () => {
    if (currentPage >= Math.round(totalCount / limit) + 1) return;
    setCurrentPage(currentPage + 1);
    scrollRef.current.scrollBy({
      left: 40,
      behavior: "smooth",
    });
    if (typeof onPaginate === "function") onPaginate(currentPage + 1);
  };

  const paginateBack = () => {
    if (currentPage <= 1) return;
    setCurrentPage(currentPage - 1);
    scrollRef.current.scrollBy({
      left: -40,
      behavior: "smooth",
    });
    if (typeof onPaginate === "function") onPaginate(currentPage - 1);
  };

  return (
    <div className="table">
      <div className="table-head d-flex justify-between align-center">
        <h2 className="raleway fs-24 f-700 text-primary">{title}</h2>
        <div className="d-flex mobile-flex-wrap header-tools align-center">
          {onSearch && (
            <Input
              onChange={onSearch}
              search={search}
              borderColor="var(--yellow-tints-5)"
              rIcon={
                <Icon>
                  <SearchIcon />
                </Icon>
              }
              placeholder="Search"
            />
          )}
          {btn1 && (
            <Button
              {...btn1}
              width={btn1.width || width <= 760 ? 50 : 170}
              title={width <= 760 ? null : btn1.title}
              lSvg={btn1.svg || width <= 760 ? btn1.icon : null}
            />
          )}
          {btn2 && (
            <Button
              {...btn2}
              width={btn2.width || width <= 760 ? 50 : 170}
              outline
              lSvg={btn2.svg || width <= 760 ? btn2.icon : null}
              title={width <= 760 ? null : btn2.title}
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: tableWidth,
          maxWidth: width <= 760 ? null : maxWidth * tableWidth,
        }}
        className="table-content"
      >
        <table>
          <tr className="fs-12 text-brown f-600">
            {head.map(({ title }) => (
              <th key={title}>{title}</th>
            ))}
          </tr>
          {loading ? (
            <tr>
              <td colSpan={head.length}>
                <div className="table-loader d-flex justify-center align-center">
                  <Loader loading />
                </div>
              </td>
            </tr>
          ) : null}
          {!data.length && !loading ? (
            <tr>
              <td colSpan={head.length}>
                <h3
                  style={{ color: "var(--black-tints-2)" }}
                  className="fs-16 text-center raleway"
                >
                  No Records
                </h3>
              </td>
            </tr>
          ) : null}
          {data.map((d, idx) => (
            <Row
              cells={head}
              data={d}
              key={idx}
              didx={currentPage <= 1 ? idx : idx + limit * (currentPage - 1)}
            />
          ))}
        </table>
      </div>
      <div className="table-footer d-flex align-center justify-end">
        {!loading && !hideFooter && (
          <>
            <span className="d-block data-info fs-14 f-400">
              {currentPage * limit - limit + 1} -{" "}
              {limit > totalCount ? totalCount : currentPage * limit} of{" "}
              {totalCount} items
            </span>
            <div className="d-flex icons">
              <Icon className="first">
                <ArrowEndLeftIcon color="var(--main-yellow)" />
              </Icon>
              <Icon onClick={paginateBack}>
                <AngleLeftIcon color="var(--main-yellow)" />
              </Icon>
              <div ref={scrollRef} className="numbers">
                {formFrom(1, Math.round(totalCount / limit) + 1).map((n) => (
                  <Icon onClick={() => setCurrentPage(n)} key={n}>
                    <span
                      className={`${
                        currentPage === n ? "active-cell" : ""
                      } d-flex justify-center align-center fs-14 f-400`}
                    >
                      {n}
                    </span>
                  </Icon>
                ))}
              </div>
              <Icon onClick={paginateNext}>
                <AngleRightIcon color="var(--main-yellow)" />
              </Icon>
              <Icon className="last">
                <ArrowEndRightIcon color="var(--main-yellow)" />
              </Icon>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Table;

/* 

const TableItems = () => (
    <>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Alfreds Futterkiste</td>
        <td>Maria Anders</td>
        <td>Germany</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status text="Success" className="success" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Centro comercial Moctezuma</td>
        <td>Francisco Chang</td>
        <td>Mexico</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status text="Warn" className="warn" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Ernst Handel</td>
        <td>Roland Mendel</td>
        <td>Austria</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status text="info" className="info" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Island Trading</td>
        <td>Helen Bennett</td>
        <td>UK</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status text="danger" className="danger" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Island Trading</td>
        <td>Helen Bennett</td>
        <td>UK</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="status-tint5" text="placed" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Laughing Bacchus Winecellars</td>
        <td>Yoshi Tannamuri</td>
        <td>Canada</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="success-text success-light" text="Success" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Magazzini Alimentari Riuniti</td>
        <td>Giovanni Rovelli</td>
        <td>Italy</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="warn-text warn-light" text="low on stock" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Magazzini Alimentari Riuniti</td>
        <td>Giovanni Rovelli</td>
        <td>Italy</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="danger-text danger-light" text="out of stock" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Laughing Bacchus Winecellars</td>
        <td>Yoshi Tannamuri</td>
        <td>Canada</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="success-text" text="Done" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Magazzini Alimentari Riuniti</td>
        <td>Giovanni Rovelli</td>
        <td>Italy</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="warn-text" text="Warn" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Magazzini Alimentari Riuniti</td>
        <td>Giovanni Rovelli</td>
        <td>Italy</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="info-text" text="Info" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Magazzini Alimentari Riuniti</td>
        <td>Giovanni Rovelli</td>
        <td>Italy</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="danger-text" text="Danger" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
      <tr className="fs-14 tr-body text-blue f-400">
        <td>
          <ID />
        </td>
        <td>Magazzini Alimentari Riuniti</td>
        <td>Giovanni Rovelli</td>
        <td>Italy</td>
        <td>
          <WithLink />
        </td>
        <td>
          <Status className="disabled" text="disabled" />
        </td>
        <Action />
        <td>
          <View />
        </td>
        <td>
          <WithImage />
        </td>
        <td>
          <ItemCount />
        </td>
      </tr>
    </>
  );

*/
