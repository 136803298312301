import { useNavigate } from "react-router-dom";
import useInventory from "../../../hooks/api/useInventory";
import Table from "../../../components/table/Table";
import { useContext, useEffect, useState } from "react";
import { formatNumber } from "../../../utils/numbers";
import { baseStatuses } from "../../../utils/statuses";
import Status from "../../../components/Status";
import WithImage from "../../../components/table/WithImage";
import useQuery from "../../../hooks/useQuery";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import AppContext from "../../../contexts/AppContext";
import { requestTypes } from "../../../utils/vars";
import NotFound from "../../../components/NotFound";
import Loader from "../../../components/Loader";
import InfoText from "../../../components/TextInfo";
import { formatDateTime } from "../../../utils/date";
import WithLink from "../../../components/table/WithLink";

const itemsHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Item",
    target: ["material.imageUrl", "material.name"],
    render: (values) => <WithImage text={values[1]} imageUrl={values[0]} />,
  },
  {
    title: "Supply Status",
    target: "status",
    render: (v) => {
      return <Status text={v} light className={baseStatuses[v]} />;
    },
  },
  {
    title: "Quantity",
    target: ["requestedQuantity", "supplyUnit"],
    render: (v) => formatNumber(v[0], "") + " " + v[1],
  },
  {
    title: "Priority",
    target: "priority",
    render: (v) => {
      return <Status text={v} light className={baseStatuses[v]} />;
    },
  },
];

function RequestsDetails(props) {
  const [selectedResponses, setSelectedResponses] = useState([]);
  const [acceptedSupply, setAcceptedSupply] = useState(null);
  const { loading, acceptSupply, confirmDelivery, sendDelivery } =
    useInventory();
  const { formatCurrency, outlet } = useContext(AppContext);
  const {
    getMaterialRequestDetails,
    data,
    loading: getLoading,
  } = useInventory();
  const navigate = useNavigate();
  const query = useQuery();

  const refresh = () => {
    getMaterialRequestDetails(query.get("id"));
    setAcceptedSupply(null);
  };

  useEffect(() => {
    if (query.get("id")) refresh();
    else navigate(-1);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.items) {
      setSelectedResponses(
        data.items
          .filter((_) => _.responses.length)
          .map((_) => ({
            item: _._id,
            response: _.responses[0],
            priceChange: 0,
          }))
      );
    }
  }, [data]);

  if (getLoading)
    return (
      <div
        style={{ height: "100%" }}
        className="d-flex align-center justify-center"
      >
        <Loader loading />
      </div>
    );
  if (!data) return <NotFound />;

  return (
    <div>
      <h2 className="text-primary">Request Details</h2>
      <br />
      <div className="d-flex flex-wrap gap-10">
        <InfoText
          className="full-flex"
          title="Sent By:"
          text={`${data.requestedBy.firstName} ${data.requestedBy.lastName}`}
        />
        <InfoText
          className="full-flex"
          title="Requested From:"
          text={data.origin.name}
        />
        <InfoText className="full-flex" title="Delivery Address:">
          <WithLink text={data.origin.location.label} />
        </InfoText>
        <InfoText
          className="full-flex"
          title="Requested Time:"
          text={formatDateTime(data.createdAt).dateTime}
        />
      </div>
      <br />
      <Modal
        closeModal={() => setAcceptedSupply(null)}
        isVisible={acceptedSupply}
      >
        <h2 className="fs-24 raleway f-700 text-primary">
          {acceptedSupply?.confirm ? "Confirm" : "Accept"} Supply
        </h2>
        <br />
        <p>
          {acceptedSupply?.confirm ? "Confirm" : "Accept"} supply of{" "}
          {acceptedSupply?.name} ({formatNumber(acceptedSupply?.quantity, "")}{" "}
          {acceptedSupply?.unit}){" "}
          {data.requestType === requestTypes.supply || !acceptedSupply?.store
            ? "from"
            : "To"}{" "}
          {data.requestType === requestTypes.supply
            ? `${
                acceptedSupply?.response?.supplier?.storeName
              } at ${formatCurrency(acceptedSupply?.response?.cost)}`
            : acceptedSupply?.store
            ? data.origin.name
            : data.source.name}
        </p>
        <br />
        <div className="d-flex gap-10">
          <Button
            outline
            width={120}
            title="Cancel"
            onClick={() => setAcceptedSupply(null)}
          />
          <Button
            loading={loading}
            onClick={() => {
              if (acceptedSupply?.store)
                sendDelivery(
                  { items: [acceptedSupply?.id] },
                  query.get("id"),
                  refresh
                );
              else if (acceptedSupply?.confirm)
                confirmDelivery(
                  { items: [acceptedSupply?.id] },
                  query.get("id"),
                  refresh
                );
              else
                acceptSupply(
                  {
                    items: [
                      {
                        id: acceptedSupply?.id,
                        supplier: acceptedSupply?.response?.supplier?._id,
                      },
                    ],
                  },
                  query.get("id"),
                  refresh
                );
            }}
            title="Continue"
          />
        </div>
      </Modal>
      <Table
        loading={loading}
        data={data?.items}
        head={[
          ...itemsHead,
          ...(data?.requestType === requestTypes.supply
            ? [
                {
                  title: "Supplier",
                  target: ["responses", "_id"],
                  render: (v) => (
                    <select
                      value={
                        selectedResponses.find((_) => _.item === v[1])?.response
                          ?._id
                      }
                      onChange={(e) => {
                        const response = v[0].find(
                          (_) => _._id === e.target.value
                        );
                        if (response)
                          setSelectedResponses((responses) => {
                            const update = {
                              item: v[1],
                              response,
                              priceChange: 0,
                            };
                            if (responses.find((_) => _.item === v[1]))
                              return responses.map((_) => {
                                if (_.item === v[1]) return update;
                                return _;
                              });
                            else return [...responses, update];
                          });
                        else
                          setSelectedResponses((responses) =>
                            responses.filter((_) => _.item !== v[1])
                          );
                      }}
                    >
                      <option>Select Supplier</option>
                      {v[0]
                        .filter((_) => _.supplier)
                        .map((_) => (
                          <option value={_._id}>{_.supplier.storeName}</option>
                        ))}
                    </select>
                  ),
                },
                {
                  title: "Proposed Price",
                  target: "_id",
                  render: (v) =>
                    formatCurrency(
                      selectedResponses.find((_) => _.item === v)?.response
                        ?.cost || 0
                    ),
                },
                {
                  title: "Price Change",
                  target: "_id",
                  render: (v) =>
                    formatCurrency(
                      selectedResponses.find((_) => _.item === v)?.response
                        ?.priceChange || 0
                    ),
                },
              ]
            : []),
          {
            title: "Action",
            target: [
              "_id",
              "requestedQuantity",
              "material.name",
              "material.unitOfMeasure",
              "status",
            ],
            render: (v) => (
              <div className="d-flex gap-10">
                {data?.requestType === requestTypes.supply ? (
                  <>
                    <Button
                      disabled={
                        // !selectedResponses.find((_) => _.item === v[0]) ||
                        v[4] !== "requested"
                      }
                      onClick={() =>
                        setAcceptedSupply({
                          response: selectedResponses.find(
                            (_) => _.item === v[0]
                          )?.response,
                          quantity: v[1],
                          name: v[2],
                          unit: v[3],
                          id: v[0],
                        })
                      }
                      width={130}
                      height={40}
                      fontSize="fs-12"
                      className="bg-info"
                      title="Accept Supply"
                    />
                    <Button
                      disabled={
                        // !selectedResponses.find((_) => _.item === v[0])?.response?.approved ||
                        v[4] === "delivered" || v[4] === "requested"
                      }
                      onClick={() =>
                        setAcceptedSupply({
                          response: selectedResponses.find(
                            (_) => _.item === v[0]
                          )?.response,
                          quantity: v[1],
                          name: v[2],
                          unit: v[3],
                          confirm: true,
                          id: v[0],
                        })
                      }
                      width={130}
                      height={40}
                      fontSize="fs-12"
                      className="bg-success"
                      title="Confirm Delivery"
                    />
                  </>
                ) : outlet === data.origin._id ? (
                  <Button
                    outline
                    borderRadius={4}
                    textClassName="success-text"
                    disabled={v[4] !== "sent"}
                    onClick={() =>
                      setAcceptedSupply({
                        response: selectedResponses.find((_) => _.item === v[0])
                          ?.response,
                        quantity: v[1],
                        name: v[2],
                        unit: v[3],
                        confirm: true,
                        id: v[0],
                      })
                    }
                    width={130}
                    height={40}
                    fontSize="fs-12"
                    className="bg-success"
                    title="Confirm Delivery"
                  />
                ) : outlet === data.source._id ? (
                  <Button
                    outline
                    borderRadius={4}
                    textClassName="success-text"
                    disabled={v[4] !== "requested"}
                    onClick={() =>
                      setAcceptedSupply({
                        response: selectedResponses.find((_) => _.item === v[0])
                          ?.response,
                        quantity: v[1],
                        name: v[2],
                        unit: v[3],
                        confirm: true,
                        store: true,
                        id: v[0],
                      })
                    }
                    width={130}
                    height={40}
                    fontSize="fs-12"
                    className="bg-success"
                    title="Mark as Delivered"
                  />
                ) : null}
              </div>
            ),
          },
        ]}
        title="Requested Items"
        hideFooter={true}
      />
    </div>
  );
}

export default RequestsDetails;
