import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import Dashboard from "../pages/app/creators/Dashboard";
import EditConcept from "../pages/app/creators/EditConcept";

function Creators(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
      <Route
        path={path.creators.new.path}
        element={<EditConcept pageTitle="Submit New Concept" />}
      />
      <Route
        path={path.creators.edit.path}
        element={<EditConcept pageTitle="Edit Concept" />}
      />
    </Routes>
  );
}

export default Creators;
