const PlayIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM16 27V13C16 12.18 16.94 11.7 17.6 12.2L26.94 19.2C27.48 19.6 27.48 20.4 26.94 20.8L17.6 27.8C16.94 28.3 16 27.82 16 27Z"
        fill={color}
      />
    </svg>
  );
};

export default PlayIcon;
