import { Form, FormInput, Submit } from "../../components/form";
import { loginSchema } from "../../utils/validators/auth";
import CheckBox from "../../components/CheckBox";
import logo from "../../assets/logo-black.png";
import useAuth from "../../hooks/api/useAuth";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import "../../css/pages/auth.css";
import { path } from "../../utils/path";

function Login(props) {
  const { login, loading } = useAuth();
  return (
    <div className="auth-page inter d-flex flex-column">
      <div className="form d-flex form-container flex justify-center align-center">
        <div className="form d-flex flex-column form-container flex justify-center align-center">
          <img src={logo} alt="iCart Africa" />
          <br />
          <br />
          <div className="form-card shadow">
            <h2 className="fs-24 f-700 text-center text-primary">Login</h2>
            <br />
            <br />
            <Form
              validationSchema={loginSchema}
              onSubmit={login}
              initialValues={{
                email: "",
                password: "",
              }}
            >
              <FormInput name="email" placeholder="Email" />
              <br />
              <FormInput password name="password" placeholder="Password" />
              <div className="d-flex options justify-between align-center">
                <div className="save d-flex align-center">
                  <CheckBox value={true} />
                  <Link className="fs-14 f-500">Remember Me</Link>
                </div>
                <Link to={path.auth.forgotPassword} className="fs-14 f-500">
                  Forgot Password?
                </Link>
              </div>
              <br />
              <Submit loading={loading} title="Sign in" />
              <br />
              <span className="fs-14 f-400 text-center d-block">
                Don’t have an account?{" "}
                <Link to={path.auth.signUp} className="f-700 text-primary">
                  Register Here
                </Link>
              </span>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
