import CheckIcon from "../assets/icons/CheckIcon";
import { useEffect, useState } from "react";

function CheckBox({ onChange, value }) {
  const [checked, setChecked] = useState(false);
  const toggleCheck = () => {
    if (typeof onChange === "function") onChange(!checked);
    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <button
      onClick={toggleCheck}
      className={`check-box d-flex justify-center align-center ${
        checked ? "checked" : ""
      }`}
    >
      {checked ? <CheckIcon /> : null}
    </button>
  );
}

export default CheckBox;
