import { useEffect, useState } from "react";
import FilePicker from "../FilePicker";
import Input from "../Input";
import Button from "../Button";
import urls from "../../api/urls";
import useInventory from "../../hooks/api/useInventory";

function Packaging({ loading, onFinished, concept, addDataToConcept, id }) {
  const [packagingImages, setPackagingImages] = useState({});
  const [packaging, setPackaging] = useState(concept?.concept?.packaging || "");
  const {
    searchMaterial,
    materials,
    loading: materialsLoading,
  } = useInventory();

  useEffect(() => {
    searchMaterial("packaging");
    // eslint-disable-next-line
  }, []);

  const savePackaging = async () => {
    const formData = new FormData();
    formData.append("packaging", packaging);
    Object.values(packagingImages).forEach((file) =>
      formData.append("images", file)
    );
    const response = await addDataToConcept(
      id,
      formData,
      urls.business.concepts.packaging
    );
    if (response) onFinished(response);
  };

  return (
    <div>
      <Input
        select
        options={materials.map((_) => ({ label: _.name, value: _._id }))}
        value={packaging}
        onChange={setPackaging}
        placeholder="Packaging"
      />
      <br />
      <div className="d-flex flex-wrap justify-between gap-10">
        <FilePicker
          initial={concept?.concept?.packageImages[0]}
          onSelect={(one) => setPackagingImages({ ...packagingImages, one })}
          width="48%"
        />
        <FilePicker
          initial={concept?.concept?.packageImages[1]}
          onSelect={(two) => setPackagingImages({ ...packagingImages, two })}
          width="48%"
        />
        <FilePicker
          initial={concept?.concept?.packageImages[2]}
          onSelect={(three) =>
            setPackagingImages({ ...packagingImages, three })
          }
          width="48%"
        />
        <FilePicker
          initial={concept?.concept?.packageImages[3]}
          onSelect={(four) => setPackagingImages({ ...packagingImages, four })}
          width="48%"
        />
      </div>
      <br />
      <Button
        loading={loading || materialsLoading}
        disabled={!packaging}
        onClick={savePackaging}
        title="Save & Continue"
      />
    </div>
  );
}

export default Packaging;
