const ViewIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        transform="translate(0 0.5)"
        fill={color}
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 15.5C6.21984 15.5 3.334 11.95 3.334 10.5C3.334 8.83248 6.2165 5.49998 10.0007 5.49998C13.6473 5.49998 16.6657 8.81081 16.6657 10.5C16.6657 11.95 13.7807 15.5 10.0007 15.5H9.99984ZM10.0007 3.83331C5.39984 3.83331 1.6665 7.86748 1.6665 10.5C1.6665 13.0716 5.47984 17.1666 9.99984 17.1666C14.519 17.1666 18.3332 13.0716 18.3332 10.5C18.3332 7.86748 14.5998 3.83331 9.99984 3.83331"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98067 12.1533C9.0615 12.1533 8.314 11.4058 8.314 10.4866C8.314 9.56748 9.0615 8.81998 9.98067 8.81998C10.9007 8.81998 11.6473 9.56748 11.6473 10.4866C11.6473 11.4058 10.9007 12.1533 9.98067 12.1533ZM9.98067 7.15331C8.14234 7.15331 6.64734 8.64831 6.64734 10.4866C6.64734 12.325 8.14234 13.82 9.98067 13.82C11.8198 13.82 13.314 12.325 13.314 10.4866C13.314 8.64831 11.8198 7.15331 9.98067 7.15331Z"
        fill={color}
      />
    </svg>
  );
};

export default ViewIcon;
