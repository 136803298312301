import { useEffect, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import WindowInfoText from "../WindowInfoText";
import urls from "../../api/urls";
import { excludeFromObject, selectFromObject } from "../../utils/array";
import Collapsable from "../Collapsable";

const Maintanance = ({ instruction, id, handleChange, remove, comment }) => (
  <>
    <div className="submit-menu-card">
      <div className="inputs">
        <Input
          value={instruction}
          placeholder="Instruction"
          onChange={(v) => handleChange(id, "instruction", v)}
        />
        <Input
          value={comment}
          placeholder="Comment"
          onChange={(v) => handleChange(id, "comment", v)}
        />
      </div>
      <br />
      <Button
        outline
        style={{ marginLeft: "auto" }}
        onClick={remove}
        borderColor="var(--alerts-danger)"
        title="Remove"
        color="danger-text"
        fontSize="fs-12"
        width={80}
        height={30}
      />
    </div>
    <br />
  </>
);

const Machines = ({
  remove,
  id,
  name,
  model,
  maintanance = [],
  handleChange,
}) => {
  const [instructions, setInstructions] = useState(maintanance);

  useEffect(() => {
    handleChange(id, "maintanance", instructions);
    // eslint-disable-next-line
  }, [instructions]);

  return (
    <Collapsable title={name}>
      <div className="submit-menu-card">
        <div className="inputs">
          <Input
            value={name}
            placeholder="Name"
            onChange={(v) => handleChange(id, "name", v)}
          />
          <Input
            value={model}
            placeholder="Model"
            onChange={(v) => handleChange(id, "model", v)}
          />
        </div>
        <br />
        <WindowInfoText label="Maintenance" />
        <br />
        <Group
          AddElement={AddInstructionButton}
          items={instructions}
          setItems={setInstructions}
          Element={Maintanance}
        />
        <br />
        <Button
          outline
          style={{ marginLeft: "auto" }}
          onClick={remove}
          borderColor="var(--alerts-danger)"
          title="Remove"
          color="danger-text"
          fontSize="fs-12"
          width={80}
          height={30}
        />
      </div>
      <br />
    </Collapsable>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

const AddInstructionButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Instruction"
    fontSize="fs-12"
    width={120}
    height={30}
  />
);

function Machinery({
  label,
  onFinished,
  id,
  concept,
  addDataToConcept,
  loading,
}) {
  const [machines, setMachines] = useState(
    concept?.machineries
      ? concept.machineries.map((_, idx) => ({
          ...selectFromObject(["name", "model", "_id"], _),
          id: idx + 1,
          maintanance: _.maintanance.map((__, idx2) => ({
            ...excludeFromObject(["_id"], __),
            id: idx2 + 1,
          })),
        }))
      : []
  );

  const saveItems = async () => {
    const response = await addDataToConcept(
      id,
      {
        machines: machines.map((_) => ({
          ...excludeFromObject(["id", "_id"], _),
          id: _._id,
          maintanance: _.maintanance.map((__) => excludeFromObject(["id"], __)),
        })),
      },
      urls.business.concepts.addMachines
    );
    if (response) onFinished(response);
  };
  return (
    <div>
      <span className="fs-14 d-block text-brown f-700">{label}</span>
      <br />
      <Group
        items={machines}
        setItems={setMachines}
        Element={Machines}
        AddElement={AddButton}
      />
      <br />
      <Button loading={loading} onClick={saveItems} title="Save & Continue" />
    </div>
  );
}

export default Machinery;
