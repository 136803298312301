function Warn(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_52_8018)">
        <mask
          id="mask0_52_8018"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <rect width="40" height="40" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_52_8018)">
          <path
            d="M20 28.3333C20.4722 28.3333 20.8681 28.1736 21.1875 27.8542C21.5069 27.5347 21.6667 27.1389 21.6667 26.6667C21.6667 26.1944 21.5069 25.7986 21.1875 25.4792C20.8681 25.1597 20.4722 25 20 25C19.5278 25 19.1319 25.1597 18.8125 25.4792C18.4931 25.7986 18.3333 26.1944 18.3333 26.6667C18.3333 27.1389 18.4931 27.5347 18.8125 27.8542C19.1319 28.1736 19.5278 28.3333 20 28.3333ZM20 21.6667C20.4722 21.6667 20.8681 21.5069 21.1875 21.1875C21.5069 20.8681 21.6667 20.4722 21.6667 20V13.3333C21.6667 12.8611 21.5069 12.4653 21.1875 12.1458C20.8681 11.8264 20.4722 11.6667 20 11.6667C19.5278 11.6667 19.1319 11.8264 18.8125 12.1458C18.4931 12.4653 18.3333 12.8611 18.3333 13.3333V20C18.3333 20.4722 18.4931 20.8681 18.8125 21.1875C19.1319 21.5069 19.5278 21.6667 20 21.6667ZM15.125 35C14.6806 35 14.2569 34.9167 13.8542 34.75C13.4514 34.5833 13.0972 34.3472 12.7917 34.0417L5.95833 27.2083C5.65278 26.9028 5.41667 26.5486 5.25 26.1458C5.08333 25.7431 5 25.3194 5 24.875V15.125C5 14.6806 5.08333 14.2569 5.25 13.8542C5.41667 13.4514 5.65278 13.0972 5.95833 12.7917L12.7917 5.95833C13.0972 5.65278 13.4514 5.41667 13.8542 5.25C14.2569 5.08333 14.6806 5 15.125 5H24.875C25.3194 5 25.7431 5.08333 26.1458 5.25C26.5486 5.41667 26.9028 5.65278 27.2083 5.95833L34.0417 12.7917C34.3472 13.0972 34.5833 13.4514 34.75 13.8542C34.9167 14.2569 35 14.6806 35 15.125V24.875C35 25.3194 34.9167 25.7431 34.75 26.1458C34.5833 26.5486 34.3472 26.9028 34.0417 27.2083L27.2083 34.0417C26.9028 34.3472 26.5486 34.5833 26.1458 34.75C25.7431 34.9167 25.3194 35 24.875 35H15.125ZM15.1667 31.6667H24.8333L31.6667 24.8333V15.1667L24.8333 8.33333H15.1667L8.33333 15.1667V24.8333L15.1667 31.6667Z"
            fill="#F7941D"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_52_8018">
          <rect width="40" height="40" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Warn;
