const AlertIcon = ({ color = "#F7941D" }) => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9875 1.66562C8.15938 2.075 9 3.1875 9 4.5C9 7.2625 10.3438 9.5 12 9.5V11.5H0V9.5C1.65625 9.5 3 7.2625 3 4.5C3 3.1875 3.84062 2.075 5.0125 1.66562C5.00625 1.6125 5 1.55625 5 1.5C5 0.946875 5.44688 0.5 6 0.5C6.55312 0.5 7 0.946875 7 1.5C7 1.55625 6.99688 1.6125 6.9875 1.66562ZM6 14.5C7.10313 14.5 8 13.6031 8 12.5H4C4 13.6031 4.89687 14.5 6 14.5Z"
        fill={color}
      />
    </svg>
  );
};

export default AlertIcon;
