import { objectToFormData } from "../../utils/array";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";

function useConcepts(props) {
  const [allConcepts, setAllConcepts] = useState([]);
  const [concept, setConcept] = useState(null);
  // eslint-disable-next-line
  const [requests, setRequests] = useState([]);
  // eslint-disable-next-line
  const { loading, post, get, put, patch, fdelete } = useAPI();

  const getConcepts = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.business.concepts.myConcepts, {
        pageNumber,
        limit,
      })
    );
    if (error) return;
    setRequests(data.concepts);
  };
  const getConcept = async (id) => {
    const { data, error } = await get(
      urls.business.concepts.baseUrl + `/${id}`
    );
    if (error) return;
    setConcept(data);
  };

  const submitConcept = async (fdata) => {
    const { data, error } = await post(urls.business.concepts.baseUrl, fdata);
    if (error) return null;
    return data;
  };

  const addDataToConcept = async (id, fdata, url, isDelete) => {
    if (!id) return toast.info("Basic Info not Provided!");
    const putUrls = [
      urls.business.concepts.details,
      urls.business.concepts.status,
    ];
    const patchEndpoints = [
      urls.business.concepts.grade,
      urls.business.concepts.regions,
    ];
    const method = isDelete
      ? fdelete
      : patchEndpoints.includes(url)
      ? patch
      : putUrls.includes(url)
      ? put
      : post;
    const { data, error, message } = await method(
      url.replace(":id", id),
      fdata
    );
    if (error) return null;
    await getConcept(id);
    toast.info(message);
    return data;
  };

  const getAllConcepts = async (pageNumber = 1, limit = 10, status) => {
    const { data, error } = await get(
      addParamsToUrl(urls.business.concepts.baseUrl, {
        pageNumber,
        limit,
        status,
      })
    );
    if (error) return;
    setAllConcepts(data.concepts);
  };

  return {
    loading,
    concept,
    requests,
    getConcept,
    getConcepts,
    allConcepts,
    submitConcept,
    getAllConcepts,
    addDataToConcept,
  };
}
export default useConcepts;
