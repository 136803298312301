const StoreIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_953)">
        <path
          d="M18.2498 7.40833L17.3748 3.76667C17.1914 3.01667 16.5414 2.5 15.7831 2.5H4.2081C3.4581 2.5 2.79976 3.025 2.62476 3.76667L1.74976 7.40833C1.54976 8.25833 1.73309 9.125 2.26643 9.80833C2.33309 9.9 2.42476 9.96667 2.49976 10.05V15.8333C2.49976 16.75 3.24976 17.5 4.16643 17.5H15.8331C16.7498 17.5 17.4998 16.75 17.4998 15.8333V10.05C17.5748 9.975 17.6664 9.9 17.7331 9.81667C18.2664 9.13333 18.4581 8.25833 18.2498 7.40833ZM15.7581 4.15833L16.6331 7.8C16.7164 8.15 16.6414 8.5 16.4248 8.775C16.3081 8.925 16.0581 9.16667 15.6414 9.16667C15.1331 9.16667 14.6914 8.75833 14.6331 8.21667L14.1498 4.16667L15.7581 4.15833ZM10.8331 4.16667H12.4664L12.9164 7.93333C12.9581 8.25833 12.8581 8.58333 12.6414 8.825C12.4581 9.04167 12.1914 9.16667 11.8498 9.16667C11.2914 9.16667 10.8331 8.675 10.8331 8.075V4.16667ZM7.07476 7.93333L7.53309 4.16667H9.16643V8.075C9.16643 8.675 8.7081 9.16667 8.09143 9.16667C7.80809 9.16667 7.54976 9.04167 7.34976 8.825C7.14143 8.58333 7.04143 8.25833 7.07476 7.93333ZM3.36643 7.8L4.2081 4.16667H5.84976L5.36643 8.21667C5.29976 8.75833 4.86643 9.16667 4.35809 9.16667C3.94976 9.16667 3.69143 8.925 3.58309 8.775C3.35809 8.50833 3.28309 8.15 3.36643 7.8ZM4.16643 15.8333V10.8083C4.23309 10.8167 4.29143 10.8333 4.35809 10.8333C5.08309 10.8333 5.74143 10.5333 6.22476 10.0417C6.72476 10.5417 7.39143 10.8333 8.14976 10.8333C8.87476 10.8333 9.52476 10.5333 10.0081 10.0583C10.4998 10.5333 11.1664 10.8333 11.9164 10.8333C12.6164 10.8333 13.2831 10.5417 13.7831 10.0417C14.2664 10.5333 14.9248 10.8333 15.6498 10.8333C15.7164 10.8333 15.7748 10.8167 15.8414 10.8083V15.8333H4.16643Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_953">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StoreIcon;
