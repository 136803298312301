const OnlineOrdersIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9165 9.58334C12.9165 11.25 10.8332 12.5 10.8332 13.75H9.1665C9.1665 12.5 7.08317 11.25 7.08317 9.58334C7.08317 7.975 8.3915 6.66667 9.99984 6.66667C11.6082 6.66667 12.9165 7.975 12.9165 9.58334ZM10.8332 14.5833H9.1665V15.8333H10.8332V14.5833ZM18.3332 10C18.3332 7.7 17.3998 5.61667 15.8915 4.10834L15.0082 4.99167C16.2915 6.275 17.0832 8.05 17.0832 10C17.0832 11.95 16.2915 13.725 15.0082 15.0083L15.8915 15.8917C17.3998 14.3833 18.3332 12.3 18.3332 10ZM2.9165 10C2.9165 8.05 3.70817 6.275 4.9915 4.99167L4.10817 4.10834C2.59984 5.61667 1.6665 7.7 1.6665 10C1.6665 12.3 2.59984 14.3833 4.10817 15.8917L4.9915 15.0083C3.70817 13.725 2.9165 11.95 2.9165 10ZM14.5832 10C14.5832 11.2667 14.0665 12.4083 13.2415 13.2417L14.1248 14.125C15.1832 13.0667 15.8332 11.6083 15.8332 10C15.8332 8.39167 15.1832 6.93334 14.1248 5.875L13.2415 6.75834C14.0665 7.59167 14.5832 8.73334 14.5832 10ZM5.87484 14.125L6.75817 13.2417C5.92484 12.4083 5.4165 11.2667 5.4165 10C5.4165 8.73334 5.93317 7.59167 6.75817 6.75834L5.87484 5.875C4.8165 6.93334 4.1665 8.39167 4.1665 10C4.1665 11.6083 4.8165 13.0667 5.87484 14.125Z"
        fill={color}
      />
    </svg>
  );
};

export default OnlineOrdersIcon;
