const KitchenIcon = ({ color = "#ffffff" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_532_91)">
        <path
          d="M6.66634 4.16666H8.33301V6.66666H6.66634V4.16666ZM6.66634 9.99999H8.33301V14.1667H6.66634V9.99999ZM14.9997 1.67499L4.99967 1.66666C4.08301 1.66666 3.33301 2.40832 3.33301 3.33332V16.6667C3.33301 17.5833 4.08301 18.3333 4.99967 18.3333H14.9997C15.9163 18.3333 16.6663 17.5833 16.6663 16.6667V3.33332C16.6663 2.40832 15.9163 1.67499 14.9997 1.67499ZM14.9997 16.6667H4.99967V9.14999H14.9997V16.6667ZM14.9997 7.49999H4.99967V3.33332H14.9997V7.49999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_91">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KitchenIcon;
