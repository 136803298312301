import SearchIcon from "../../../assets/icons/SearchIcon";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import Input from "../../../components/Input";
import img1 from "../../../assets/img1.png";
import img2 from "../../../assets/img2.png";
import img3 from "../../../assets/img3.png";
import img4 from "../../../assets/img4.png";
import { useNavigate } from "react-router-dom";
import VideoInfo from "../../../components/Video";

function Videos(props) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="d-flex flex-wrap support-header justify-between align-center">
        <div className="text-info">
          <h2 className="fs-24 raleway f-700 text-primary">Watch Tutorials</h2>
          <br />
          <p className="fs-14 text-blue f-400">
            View iCart menus and watch tutorials on meal preparations
          </p>
          <br />
        </div>
        <div className="d-flex support-search">
          <div style={{ flex: 1 }}>
            <Input
              borderColor="#7B4A0E"
              placeholder="Search"
              rIcon={
                <Icon>
                  <SearchIcon />
                </Icon>
              }
            />
          </div>
          <Button title="See all menu list" width={160} />
        </div>
      </div>
      <br />
      <div className="d-flex flex-wrap videos">
        <VideoInfo navigate={navigate} img={img1} />
        <VideoInfo navigate={navigate} img={img2} />
        <VideoInfo navigate={navigate} img={img3} />
        <VideoInfo navigate={navigate} img={img4} />
        <VideoInfo navigate={navigate} img={img4} />
        <VideoInfo navigate={navigate} img={img3} />
        <VideoInfo navigate={navigate} img={img2} />
        <VideoInfo navigate={navigate} img={img1} />
      </div>
    </div>
  );
}

export default Videos;
